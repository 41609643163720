import store from '@/store'

var axios = require('axios')

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.accessToken
    if (token) {
      config.headers = { Authorization: `Bearer ${token}` }
    }
    // config.headers['Content-Type'] = 'application/json'
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    // Prevent infinite loops
    if (error.response && error.response.status === 401 && error.config.url === 'api/token/refresh/') {
      store.dispatch('logoutUser')
      return Promise.reject(error)
    }

    if (error.response && error.response.status === 401 && !error.config._retry) {
      localStorage.removeItem('accessToken')
      const refreshToken = localStorage.refreshToken
      if (!refreshToken) { return Promise.reject(error) }
      return axiosInstance
        .post('api/token/refresh/', { refresh: refreshToken })
        .then((response) => {
          localStorage.setItem('accessToken', response.data.access)
          return axiosInstance(error.config)
        })
    }
    return Promise.reject(error)
  }
)
export default axiosInstance
