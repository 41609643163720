<template>
  <div id="app">
    <confirm-modal/>
    <progress-dlg/>
    <div v-if="isRedirectChecking" class="fixed mx-auto w-full h-full bg-neutral-300 bg-opacity-50 cursor-wait z-50">
      <div class="flex w-full h-full items-center justify-center">
        <svg class="animate-spin h-12 w-12 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
    </div>
    <template v-else>
      <div class="fixed bottom-0 w-full mb-10 z-50">
        <div v-for="(notify, index) in notifications" :key="index" class="flex justify-center">
          <Alert :notify="notify"></Alert>
        </div>
      </div>
      <router-view/>
    </template>
    <!-- <cookies-view></cookies-view> -->
  </div>
</template>

<!-- import CookiesView from '@/views/CookiesView.vue' -->
<!-- CookiesView-->

<script>
import { mapActions, mapGetters } from 'vuex'
import Alert from '@/components/Alert.vue'
import ApiClient from '@/assets/js/ApiClient.js'

export default {
  components: {
    Alert
  },
  computed: {
    ...mapGetters({
      notifications: 'notifications',
      accessToken: 'accessToken',
      refreshToken: 'refreshToken',
      hasAccess: 'accounts/appPermissions/hasAccess',
      user: 'user'
    }),
    language () {
      return this.user.language
    },
    hasAccessToken () {
      return (this.accessToken !== null)
    },
    isRedirectChecking () {
      return (this.$route.query.redirect && this.hasAccessToken)
    }
  },
  methods: {
    ...mapActions(['logoutUser'])
  },
  created () {
    if (this.$route.path === '/logout') {
      if (this.hasAccessToken && this.$route.query.autofill_email) {
        ApiClient.get('accounts/users/me/').then(response => {
          const tmpuser = response.data
          this.logoutUser(this.$route.query.redirect ? `?redirect=${this.$route.query.redirect}&email=${tmpuser.email}` : `?email=${tmpuser.email}`)
        })
      } else {
        this.logoutUser(this.$route.query.redirect ? `?redirect=${this.$route.query.redirect}` : null)
      }
    } else {
      setTimeout(() => {
        if (this.hasAccessToken) {
          if (this.$route.query.redirect) {
            const form = document.createElement('form')
            form.method = 'POST'
            form.action = this.$route.query.redirect
            const input = document.createElement('input')
            input.type = 'hidden'
            input.name = 'access_token'
            input.value = this.accessToken
            form.appendChild(input)
            document.body.appendChild(form)
            form.submit()
          }
        }
      }, 1500)
    }
  },
  watch: {
    user: {
      handler (val) {
        const langs = ['de', 'fr', 'it']
        this.$root.$i18n.locale = langs[val.language - 1]
      },
      deep: true
    }
  }
}

</script>
