<template>
<div class="mx-auto">
  <portal to="toolbarExt">
    <router-link to="/ika/" class="bg-secondary text-white px-3 py-2 rounded-md text-sm font-medium">{{ $t('back') }}</router-link>
  </portal>
  <edit-evaluation v-if="evaluation" :item="evaluation" :readOnlyView="true" :resultDataInit="results" :language="this.language"></edit-evaluation>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EditEvaluation from '../evaluations/EditEvaluation.vue'
export default {
  components: {
    EditEvaluation

  },
  data () {
    return {
      evaluation: null
    }
  },
  computed: {
    ...mapGetters({
      fId: 'ika/results/fId',
      resultsSelf: 'ika/results/resultsSelf',
      resultsExternal: 'ika/results/resultsExternal',
      language: 'ika/results/language',
      hospital: 'ika/results/hospital'
    }),
    isContinueEnabled () {
      return this.editFid != null && this.editFid > 0 && this.confirmed === true && this.isLoading === false
    },
    hasEntryResultsSelf () {
      return this.resultsSelf.filter(p => p.attributes.entry_exit === '1').length > 0
    },
    hasEntryResultsExternal () {
      return this.resultsExternal.filter(p => p.attributes.entry_exit === '1').length > 0
    },
    hasExitResultsSelf () {
      return this.resultsSelf.filter(p => p.attributes.entry_exit === '2').length > 0
    },
    hasExitResultsExternal () {
      return this.resultsExternal.filter(p => p.attributes.entry_exit === '2').length > 0
    },
    hasResultsEntry () {
      return this.hasEntryResultsSelf || this.hasEntryResultsExternal
    },
    hasResultsExit () {
      return this.hasExitResultsSelf || this.hasExitResultsExternal
    },
    hasResults () {
      return this.hasResultsExit || this.hasResultsEntry
    },
    results () {
      const nonCheckKeys = ['age', 'entry_exit', 'fid', 'gender', 'id', 'random']
      // Filter results if all values are null
      var tmpSelf = this.resultsSelf.filter(res => {
        for (const key of Object.keys(res.attributes)) {
          if (nonCheckKeys.indexOf(key) === -1 && res.attributes[key] !== null) {
            return true
          }
        }
        return false
      })
      var tmpExternal = this.resultsExternal.filter(res => {
        for (const key of Object.keys(res.attributes)) {
          if (nonCheckKeys.indexOf(key) === -1 && res.attributes[key] !== null) {
            return true
          }
        }
        return false
      })
      return [...tmpSelf, ...tmpExternal]
    }
  },
  methods: {
    ...mapActions({
      loadResults: 'ika/results/load',
      addToken: 'ika/results/addToken',
      reset: 'ika/results/reset',
      updateFId: 'ika/results/updateFId'
    })

  },
  async mounted () {
    if (!this.hasResults) {
      this.$router.replace({ path: '/ika' })
    }
    var tmpQuery = { byPermissions: 1 }
    if (this.hospital) {
      tmpQuery.hospitalId = this.hospital
    }
    const tmp = await this.$store.dispatch('ika/evaluations/load', { query: tmpQuery, callback: true, pagination: false })
    this.evaluation = tmp[0].evaluation
  }
}
</script>
