<template>
  <div v-if="visible" class="mpointer-events-none fixed w-full h-full top-0 left-0 flex items-start z-50">
    <div class="absolute w-full h-full bg-neutral-900  opacity-50"></div>

    <div class="bg-white dark:bg-neutral-900 w-1/3 mx-auto rounded shadow-lg z-50 overflow-y-auto mt-32 confirm-modal">

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="modal-content py-4 text-left px-6 confirm-modal">
        <div class="flex justify-start items-center pt-2 pb-3">
          <svg v-if="type === 'warning' || type === 'info' || isRed" xmlns="http://www.w3.org/2000/svg"
            class="mr-2 fill-current w-8 h-8"
            :class="[type === 'warning' ? 'text-yellow-500' : isRed ? 'text-red-500' : 'text-blue-500']"
            viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.5 5h3l-1 10h-1l-1-10zm1.5 14.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"/></svg>
          <h1 class="flex-grow">{{ $t(title) }}</h1>
        </div>
        <p class="mt-3 mb-6" v-html="$t(text)"></p>
        <div v-if="type === 'critical'" class="my-3 p-3 bg-red-100 rounded-lg">
          <p class="py-2 mb-2">
            Bitte gib zur Bestätigung folgendes ein: <span class="text-mono bg-neutral-200 py-1 px-2 rounded-lg">DELETE ALL</span>
          </p>
          <input v-model="confirmTest" type="text" class="w-full">
        </div>

        <div class="flex justify-end space-x-2">
          <button class="cancel" v-if="type !== 'info' && type !== 'error'" @click="hide">{{ $t('warning-btn-cancel') }}</button>
          <button v-if="type === 'critical'" class="bg-red-600 hover:bg-red-800" :disabled="!isCriticalOk" @click="confirm">JA, löschen</button>

          <template v-else>
          <button class="cancel" v-if="types == 'only'" @click="confirm">{{ $t(`warning-btn-don't-save`) }}</button>
          <!-- <button v-if="types == 'only'" @click="saveItem(false)">{{ $t('warning-btn-save') }}</button> -->
          <button v-if="types != 'only'" @click="confirm">OK</button>
        </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { Modal } from '@/plugins/plugins.js'

export default {
  data () {
    return {
      visible: false,
      title: '',
      text: '',
      type: null,
      types: '',
      confirmTest: ''
    }
  },
  computed: {
    isRed () {
      return this.type === 'error' || this.type === 'critical'
    },
    isCriticalOk () {
      return this.confirmTest === 'DELETE ALL'
    },
    isNewItem () {
      return this.$route.params.primaryKey === '+'
    },
    path () {
      return `${this.$route.params.app}/${this.$route.params.collection}`
    }
  },
  methods: {
    hide () {
      this.visible = false
      this.confirmTest = ''
    },
    confirm () {
      this.confirmTest = ''
      if (typeof this.onConfirm === 'function') {
        this.onConfirm()
        this.hide()
      } else {
        this.hide()
      }
    },
    show (params) {
      this.confirmTest = ''
      this.visible = true
      this.title = params.title
      this.text = params.text
      this.type = params.type
      this.types = params.types || ''
      this.onConfirm = params.onConfirm
    },
    saveItem (close) {
      var action = ''
      if (this.isNewItem) {
        action = 'add'
      } else {
        action = 'update'
      }
      this.$store.dispatch('saveItem', { path: this.path, action: action, close: close }).then((res) => {
        if (close) {
          this.$router.replace(`/${this.path}`)
          return
        }
        if (this.isNewItem) {
          this.$router.replace(`/${this.path}/${res.id}`)
        }
      })
      this.confirm()
    }
  },
  beforeMount () {
    Modal.EventBus.$on('show', (params) => {
      this.show(params)
    })
  }
}

</script>
