import Crud from '../../crud'

import { i18n } from '@/main.js'
var crud = new Crud('ika/hospitals')

export default {
  namespaced: true,
  state: () => ({
    ...crud.state
  }),
  getters: {
    ...crud.getters,
    canDeleteMulti: state => false,
    hasHistory: () => false,
    contentType: (state, getters, rootState, rootGetters) => rootGetters['accounts/contenttypes/getByName']('ika.hospital').id,
    exportActions (state, getters, rootState, rootGetters) {
      const ct = rootGetters['accounts/contenttypes/getByName']('ika.hospital').id
      return [
        {
          name: 'Spitalliste',
          path: 'ika/hospitals/export/?byPermissions=0'
        },
        {
          name: 'Berechtigungsliste',
          path: `accounts/app_permissions/export/?content_type=${ct}`,
          relationship_id_field: 'object_ids'
        }
      ]
    },
    title: (state, getters, rootState, rootGetters) => (id) => {
      const item = getters.detailItem
      if (item) {
        return item.name
      } else {
        i18n.t('ika.hospitals')
      }
    },
    canAddMailJob: () => true,
    queryGet: () => { return { byPermissions: 0 } },
    measurementTypes: () => [
      { id: 1, value: 'HoNOS' },
      { id: 2, value: 'BSCL' },
      { id: 3, value: 'HoNOSCA' },
      { id: 4, value: 'HoNOSCA-SR' }
    ],
    types: () => [
      { id: 1, value: 'KJP - 1' },
      { id: 2, value: 'EP - 2' }
    ],
    languages: () => [
      { id: 1, value: i18n.t('german') },
      { id: 2, value: i18n.t('french') }
    ],
    fields (state, getters) {
      return [
        { text: 'Id', value: 'id', hideList: true, hideEdit: true, hideCreate: true },
        { text: 'Name', value: 'name', width: -1, showSelect: true },
        {
          text: 'Messungen',
          value: 'measurement_types',
          type: 'select',
          width: 220,
          options: getters.measurementTypes,
          multiselect: true
        },
        {
          text: 'Typ',
          value: 'type',
          type: 'select',
          width: 220,
          options: getters.types
        },
        {
          text: 'Sprache',
          value: 'language',
          type: 'select',
          width: 150,
          options: getters.languages
        },
        { text: 'Benutzer', value: 'users', type: 'component', componentName: 'Users', width: 120, hideCreate: true, hideList: true }
      ]
    }
  },
  mutations: {
    ...crud.mutations
  },
  actions: {
    ...crud.actions
  }
}
