<template>
    <div class="flex items-center justify-center h-screen bg-gradient-to-t from-blue-900 to-blue-500">
   <div class="w-full max-w-sm -mt-32">
     <div class="text-center text-4xl md:text-7xl mb-1 md:mb-10 text-white tracking-wide flex items-center justify-center whitespace-nowrap font-bold">
       GET VIZER
    </div>
  <template v-if="this.$route.params.token === undefined">
     <LanguageSwitcher class="justify-end"></LanguageSwitcher>

  <form class="bg-white dark:bg-neutral-800 shadow-md rounded px-8 pt-6 pb-8 mb-4" @submit.prevent="login">
    <div class="mb-4">
      <label for="email">
        {{$t('email')}}
      </label>
      <input v-model="email" id="email" type="email" :placeholder="$t('email')" :class="[isValidEmail ? '' : 'focus:border-red-500 border-red-500 bg-red-200', '']">
    </div>
    <button type="submit" class="w-full bg-primary" :class="{'hover:bg-primary cursor-not-allowed' : !canLogin}" :disabled="!canLogin" >
        {{$t('get-login-link')}}
    </button>
  </form>
  </template>
  <Footer></Footer>
</div>
</div>
</template>

<script>
import { mapActions } from 'vuex'
import ApiClient from '../assets/js/ApiClient.js'
import LanguageSwitcher from '../components/LanguageSwitcher.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    LanguageSwitcher,
    Footer
  },
  data () {
    return {
      response: null,
      email: ''
    }
  },
  computed: {
    canLogin () {
      return this.email !== '' && this.isValidEmail
    },
    isValidEmail () {
      return this.email === '' || (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email))
    }
  },
  methods: {
    ...mapActions(['updateAccessToken', 'updateRefreshToken', 'initialize']),
    login () {
      ApiClient.post('signup-demo/', {
        email: this.email.toLowerCase(),
        referrer_url: process.env.VUE_APP_URL,
        lang: this.$root.$i18n.locale
      }).then((response) => {
        this.$store.dispatch('addNotification', { title: this.$t('succeed'), message: 'Prüfe dein Email Posteingang' })
        this.email = ''
      }).catch((err) => {
        this.$store.dispatch('addNotification', { title: this.$t('error'), message: err.reponse.data.error, error: true })
      })
    }
  },
  mounted () {
    if (this.$route.params.token) {
      ApiClient.post(`login-demo/${this.$route.params.token}/`).then((response) => {
        const data = response.data
        this.updateAccessToken(data.access)
        this.updateRefreshToken(data.refresh)
        this.initialize()
        this.$router.replace('/results/evaluations/91')
      }).catch((err) => {
        this.$store.dispatch('addNotification', { title: this.$t('error'), message: err.reponse.data.error, error: true })
      })
    }
  }
}
</script>
