<template>
  <div v-if="visible" class="fixed w-full h-full top-0 left-0 flex items-start z-50">
    <div class="absolute w-full h-full bg-neutral-900 opacity-50" @click="$emit('cancel')"></div>

    <div class="bg-white dark:bg-neutral-800 w-1/5 mx-auto rounded shadow-lg z-50 overflow-y-auto mt-56">

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="modal-content py-4 text-left px-6">
        <div class="flex-row justify-start items-center pt-2 pb-3">
          <h1 class="flex-grow mb-2">{{ $t('move-to') }}...</h1>
          <div class="border dark:border-neutral-600">
            <div v-for="(tab, index) in tabs" :key="index">
                <div v-if="index !== chart.tabIndex" class="cursor-pointer border dark:border-neutral-600 px-2 py-1 hover:bg-secondary hover:text-white"
              @click="$emit('on-tab-selected', index)">
                {{tab.name}}
                </div>
            </div>
          </div>
          <div class="flex justify-end space-x-2 mt-2">
          <button class="cancel" @click="$emit('cancel')">{{ $t('cancel') }}</button>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      requred: true
    },
    tabs: {
      type: Array,
      requred: true
    },
    chart: {
      type: Object,
      required: false
    }
  }
}
</script>
