import Crud from '../../crud'
// import { i18n } from '@/main.js'
var crud = new Crud('irp/irpGlobalFiles')

export default {
  namespaced: true,
  state: () => ({
    ...crud.state
  }),
  getters: {
    ...crud.getters,
    contentType: (state, getters, rootState, rootGetters) => rootGetters['accounts/contenttypes/getByName']('irp.irpGlobalFiles').id,
    fields (state, getters) {
      return [
        { text: 'Id', value: 'id', hideList: true, hideEdit: true, hideCreate: true },
        { text: 'Name', value: 'name', width: -1, showSelect: true },
        // { text: 'Strasse / Nr', value: 'street_nr', width: 150, showSelect: false },
        // { text: 'Postfach', value: 'postbox', width: 80 },
        // { text: 'Plz', value: 'plz', width: 60, showSelect: false },
        // { text: 'Ort', value: 'place', width: 80, showSelect: false },
        // { text: 'Benutzer', value: 'users', type: 'component', componentName: 'Users', width: 120, hideCreate: true, hideList: true },
        { text: 'Dateien', value: 'files', type: 'component', componentName: 'Files', hideCreate: true, hideList: true, width: 120 }
      ]
    }
  },
  mutations: {
    ...crud.mutations
  },
  actions: {
    ...crud.actions
  }
}
