<template>
  <div class="w-full">
    <h1>{{$t('your-access')}}</h1>
    <div v-if="isLoading">
      <div class="w-72 h-full border-2 mt-3 mr-2 mb-2 py-5 px-6 flex flex-col flex-grow rounded dark:border-neutral-700">
        <div class="flex animate-pulse items-center mb-5">
          <div class="h-8 bg-neutral-400 dark:bg-neutral-600 w-full rounded"></div>
        </div>
        <div class="flex animate-pulse items-center mb-2">
          <div class="h-6 bg-neutral-400 dark:bg-neutral-600 w-full rounded"></div>
        </div>
        <div class="flex animate-pulse items-center mb-2">
          <div class="h-6 bg-neutral-400 dark:bg-neutral-600 w-full rounded"></div>
        </div>
        <div class="flex animate-pulse items-center mb-2">
          <div class="h-6 bg-neutral-400 dark:bg-neutral-600 w-full rounded"></div>
        </div>
      </div>
    </div>
    <div v-else class="mt-3 flex flex-wrap">
      <div v-if="!hasAccess && !evaluations.length" class="border-2 dark:border-neutral-700 p-3 w-72 h-48 dark:text-neutral-200 flex justify-center items-center text-center">
        <div>
          <div class="text-3xl mb-2">⛔</div>
          {{$t('no-app-access-found')}}
        </div>
      </div>
      <div v-for="(app, index) in appsFiltered" :key="index">
        <div v-if="permissionsByApp(app).length" class="m-2">
          <div v-if="permissionsByApp(app).length"
            class="w-72 h-full border-2 mb-2 py-4 px-6 flex flex-col flex-grow rounded">
            <div class="font-bold text-xl mb-3">
              <span v-if="$te(app.name)">{{ $t(app.name) }}</span>
              <span v-else>{{app.name}}</span>
            </div>
            <div v-for="(access, index2) in permissionsByApp(app)" :key="`${index}_${index2}`">
                <div v-if="app.internal">
                  <router-link class="w-full flex flex-wrap justify-between border text-neutral-600 dark:text-neutral-300  text-sm p-2 mb-1 hover:bg-neutral-100 dark:hover:bg-neutral-900 rounded hover:text-secondary"
                    :to="{ path: app.path, query: { objectId: access.id }}"
                  >
                    <span v-if="$te(access.name)">{{ $t(access.name) }}</span>
                    <span v-else>{{access.name}}</span>
                    <span v-if="access.category_text" class="justify-start text-xs">{{access.category_text}}</span>

                    <svg class="fill-current w-4 h-4 ml-auto shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"/></svg>
                  </router-link>
                </div>
                <div v-else>
                  <a class="w-full flex flex-wrap justify-between border text-neutral-600 dark:text-neutral-300 text-sm p-2 mb-1 hover:bg-neutral-100 dark:hover:bg-neutral-900 rounded hover:text-secondary cursor-pointer"
                    @click="onOpenExternalApp(app.url, access.key)">
                    <span v-if="$te(access.name)">{{ $t(access.name) }}</span>
                    <span v-else>{{access.name}}</span>
                    <span v-if="access.category_text"  class="justify-start text-xs">{{ access.category_text}}</span>

                    <svg class="fill-current w-4 h-4 ml-auto shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"/></svg>
                  </a>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-2">
        <div v-if="evaluations.length" class="w-72 h-full border-2 mr-2 mb-2 py-4 px-6 flex flex-col flex-grow rounded dark:border-neutral-700">
          <div class="font-bold text-xl mb-3">
            <span>VIZER</span>
          </div>
          <div v-for="ev in evaluations" :key="ev.id">
            <a class="w-full flex flex-wrap justify-between items-center border text-neutral-600 dark:text-neutral-300 text-sm p-2 mb-1 hover:bg-neutral-100 dark:hover:bg-neutral-900 rounded hover:text-secondary"
              :href="'results/evaluations/'+ev.id">
              <span v-if="$te(ev.name)">{{ $t(ev.name) }}</span>
              <span v-else>{{ev.name}}</span>
              <span v-if="ev.measures_name"  class="justify-start text-xs">{{ev.measures_name}}</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-4 h-4 ml-auto mr-0 shrink-0" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
            </a>
          </div>
        </div>
      </div>

      <div v-for="(app, index) in appstoLimesurvey" :key="index">
        <div v-if="ifFspUser(app)" class="m-2">
            <div class="w-72 h-full border-2 mr-2 mb-2 py-4 px-6 flex flex-col flex-grow rounded dark:border-neutral-700">
              <div class="font-bold text-xl mb-3">
                <span>{{ $t('Umfragen') }}</span>
              </div>
              <div>
                <a
                  class="w-full flex flex-wrap justify-between items-center border
                      text-neutral-600 dark:text-neutral-300 text-sm p-2 mb-1
                      hover:bg-neutral-100 dark:hover:bg-neutral-900
                        rounded hover:text-secondary"
                  :href="'https://survey.w-hoch2.ch/index.php/692395'"
                  target=”_blank”>
                  <span>{{ $t('Zur Umfrage') }}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-4 h-4 ml-auto mr-0 shrink-0" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
                </a>
              </div>
            </div>

        </div>
      </div>

    </div>
    <div v-if="!isLoading && files.length">
      <h1 class="mt-4 mb-4">{{ $t('filemanager.files') }}</h1>
      <div class="border-2 rounded">
        <v-table :headers="fieldsFile" :items="files" :selectable="true" @click="onDownloadFile"> </v-table>
        <Pagination :path="'filemanager/files'"/>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import VTable from '@/components/v-table.vue'
import { mapGetters } from 'vuex'
import { uniqBy } from 'lodash'
import utilsMixin from '@/mixins/utils'
import ApiClient from '@/assets/js/ApiClient.js'

export default {
  mixins: [utilsMixin],
  name: 'Home',
  components: {
    'v-table': VTable,
    Pagination
  },
  data: function () {
    return {
      isFspUser: false
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
      results: 'results/data/all',
      appPermissions: 'accounts/appPermissions/all',
      objectByContentType: 'accounts/contenttypes/objects',
      getMeasureById: 'results/measures/getById',
      evaluations: 'results/evaluations/all',
      files: 'filemanager/files/all',
      user: 'user',
      apps: 'apps/apps/all'
    }),
    hasAccess () {
      if (this.apps.length === 0) {
        return false
      }
      for (const app of this.apps) {
        if (this.permissionsByApp(app).length > 0) {
          return true
        }
      }
      return false
    },
    fieldsFile () {
      var fields = this.$store.getters['filemanager/files/fields'].filter(h => h.showSelect)
      const ix = fields.findIndex(f => f.value === 'content_object.name')
      delete fields[ix].format
      return fields
    },
    appstoLimesurvey () {
      return this.apps.filter(app => app.id === 28)
    },
    appsFiltered () {
      return this.apps.filter(app => app.internal === false || app.path)
    }
  },
  methods: {
    onOpenExternalApp (url, key) {
      const form = document.createElement('form')
      form.method = 'POST'
      form.action = this.buildUrl(url)
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'access_token'
      input.value = this.$store.state.accessToken
      form.appendChild(input)
      document.body.appendChild(form)
      form.submit()
    },
    onDownloadFile (item) {
      ApiClient.get(`/filemanager/files/${item.id}/download/`, { responseType: 'blob' }).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', `${item.name}.${item.extension}`)
        document.body.appendChild(fileLink)
        fileLink.click()
      }).catch(err => {
        console.log(err)
      })
    },
    ifFspUser (app) {
      var permissions = this.appPermissions.filter(p => p.user === this.user.id && p.content_type === app.content_type && (app.role === null || app.role >= p.role)).map(p => {
        return { ...this.objectByContentType(p.content_type, p.object_id) }
      })
      return permissions.some(item => item.app === 28)
    },
    permissionsByApp (app) {
      var permissions = this.appPermissions.filter(p => p.user === this.user.id && p.content_type === app.content_type && (app.role === null || app.role >= p.role)).map(p => {
        return { ...this.objectByContentType(p.content_type, p.object_id) }
      })
      // filter by app
      permissions = permissions.filter(p => p.app === undefined || p.app === app.id)
      return permissions
    },
    buildUrl (url) {
      if (!url) {
        return ''
      }
      if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_PREFIX === 'stage') {
        url = url.replace('//', '//stage.')
        url = url.replace('www.', '')
      }
      return `${url}`
    },
    evaluationsByObject (obj) {
      return this.evaluations.filter(ev => ev.content_type === obj.ct && ev.object_id === obj.object_id)
    },
    measureName (id) {
      return this.getMeasureById(id) ? this.getMeasureById(id).name : ''
    },
    onClickEvaluation (id) {
      this.$router.push({ path: `/results/analytics/${id}` })
    },
    getMeasures (results) {
      var uniqueList = uniqBy(results, v => [v.content_type, v.object_id].join())
      var result = uniqueList.map(r => {
        return {
          measureId: r.measure,
          objectId: r.object_id,
          contentType: r.content_type
        }
      })
      return result
    }
  },
  async mounted () {
    await this.$store.dispatch('apps/apps/load', { all: true })
    await this.$store.dispatch('patzu/hospitals/load', { all: true, query: { permissions__user: this.user.id } })
    await this.$store.dispatch('moniq/hospitals/load', { all: true, query: { permissions__user: this.user.id } })
    await this.$store.dispatch('ika/hospitals/load', { all: true, query: { permissions__user: this.user.id } })
    await this.$store.dispatch('irp/hospitals/load', { all: true, query: { permissions__user: this.user.id } })
    await this.$store.dispatch('apps/groups/load', { all: true, query: { permissions__user: this.user.id } })
    await this.$store.dispatch('accounts/appPermissions/load', { all: true, query: { user: this.user.id } })
    await this.$store.dispatch('results/evaluations/load', { all: true, query: { user: this.user.id } })
    await this.$store.dispatch('filemanager/files/load', { query: { byPermissions: '1', size: 10 } })
    console.log('check-should-work!!')
  }
}
</script>
