<template>
<div class="w-32">
    <div v-if="state === 4" class="text-xs mr-2 p-1 border border-neutral-500 rounded text-center select-none bg-blue-500 text-white">
        {{ $t('irp.confirmed') }}
    </div>
    <div v-else-if="state === 2" class="text-xs mr-2 p-1 border border-green-500 rounded text-center bg-green-500 text-white">
        {{ $t('irp.yes') }}
    </div>
    <div v-else-if="state === 3" class="text-xs mr-2 p-1 border border-red-500 rounded text-center bg-red-500 text-white">
        {{ $t('irp.no') }}
    </div>
    <div v-else-if="state === 5" class="text-xs mr-2 p-1 border border-red-600 rounded text-center bg-red-600 text-white">
        {{ $t('irp.cancelled-review') }}
    </div>
    <div v-else class="text-xs whitespace-nowrap mr-2 p-1 border border-neutral-300 rounded text-center select-none bg-neutral-400 text-white">
        {{ $t('irp.not-confirmed') }}
    </div>
</div>
</template>

<script>
export default {
  props: {
    state: {
      type: Number,
      required: true
    }
  }
}
</script>
