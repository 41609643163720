<template>
  <div v-if="measure">
    <div v-if="hasMultipleMeasures" class="mb-3 mt-2">
      <label>Messung</label>
      <v-select v-model="measure" :options="measureSelection" :mandatory="true"  @changed="initData"></v-select>
    </div>
    <div v-for="(h, index) in filterHeaders" :key="index" class="my-2">
      <v-select v-model="filters[h.value]" :options="getSelectionForKey(h.value)" :mandatory="false" @changed="updateFilter"></v-select>
    </div>

    <table class="table-auto">
        <thead>
        <tr class="bg-secondary text-white">
            <th v-for="(header, index) in headers[measure]" :key="index" class="font-normal text-sm uppercase py-3 cursor-pointer" @click="updateSortKey(header.value)">
              <div class="flex">
              <span class="whitespace-nowrap">
              {{header.text}}
              </span>
            <span><svg transform="scale(1,-1)" v-if="sortKey === header.value"
                class="shrink-0 ml-1 w-5 h-5 text-white  fill-current"
                :class="{'transform' : sortOrder === -1 }"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                >
                <path d="M0 0h24v24H0z" fill="none"/><path d="M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z"/>
              </svg>
            </span>
              </div>
            </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in getItems" :key="index" :class="[index%2 === 0 ? '' : 'bg-neutral-100 dark:bg-neutral-900']">
            <td v-for="(header, index) in headers[measure]" :key="index">
            {{renderItem(header.value, item)}}
            </td>
        </tr>
        </tbody>
    </table>
  </div>
</template>

<script>
import { clone, get } from 'lodash'
import VSelect from '@/components/v-select'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import { DATE_FORMATS } from '@/constants.js'
export default {
  props: {
    itemsInit: {
      type: Object,
      required: true
    },
    headers: {
      type: Object,
      required: true
    },
    measures: {
      type: Array,
      required: true
    }
  },
  components: {
    VSelect
  },
  data () {
    return {
      sortKey: null,
      sortOrder: 1,
      measure: null,
      filters: {},
      items: {},
      itemsFiltered: {}
    }
  },
  computed: {
    ...mapGetters({
      measureSelect: 'results/measures/forSelection',
      measureById: 'results/measures/getById'
    }),
    hasMultipleMeasures () {
      return this.measures && this.measures.length > 1
    },
    allAttributes () {
      return Object.values(this.series).flat()
    },
    measureSelection () {
      return this.measureSelect.filter(m => this.measures.indexOf(m.id) > -1)
    },
    getFilters  () {
      return this.filters
    },
    getItems () {
      return this.itemsFiltered
    },

    filterHeaders () {
      return this.headers[this.measure].filter(h => h.render_text_filter_attribute === true)
    }
  },
  methods: {
    getSelectionForKey (key) {
      return this.items.reduce(function (acc, value) {
        if (acc.indexOf(value[key]) === -1) {
          acc.push(value[key])
        }
        return acc
      }, [])
    },
    updateSortKey (value) {
      this.sortKey = value
      this.sortOrder = this.sortOrder * -1
      this.updateSort()
    },
    updateSort () {
      this.itemsFiltered = this.itemsFiltered.sort((a, b) => {
        if (this.isDate(this.sortKey)) {
          var check = dayjs(this.renderItem(this.sortKey, a), DATE_FORMATS).isAfter(dayjs(this.renderItem(this.sortKey, b), DATE_FORMATS)) ? 1 : -1
          return check * this.sortOrder
        }
        return this.renderItem(this.sortKey, a).localeCompare(this.renderItem(this.sortKey, b)) * this.sortOrder
      })
    },
    renderItem (header, item) {
      if (Array.isArray(item[header])) {
        return get(item, header).join(', ')
      }
      return get(item, header) ?? ''
    },
    isDate (key) {
      var item = this.headers[this.measure].find(item => item.value === key)
      return item.type === 'date' || item.type === 'date_range'
    },
    updateFilter () {
      Object.keys(this.filters).forEach(key => {
        this.itemsFiltered = this.items.filter(item => item[key] === this.filters[key])
      })
      this.updateSort()
    },
    initData () {
      this.items = this.itemsInit[this.measure].filter(
        item => this.headers[this.measure].filter(
          h => Array.isArray(item[h.value]) ? item[h.value].length : (h.type !== 'date' && h.type !== 'date_range' && h.render_text_filter_attribute !== true && get(item, h.value) !== null && get(item, h.value) !== undefined && get(item, h.value).trim() !== '')).length)
      this.itemsFiltered = clone(this.items)
      if (this.headers[this.measure].length) {
        this.updateSortKey(this.headers[this.measure][0].value)
      }
    }
  },
  mounted () {
    this.measure = this.measures[0]
    this.initData()
  }
}

</script>
