import Crud from '../../crud'
import { i18n } from '@/main.js'
var crud = new Crud('patzu/orders')

export default {
  namespaced: true,
  state: () => ({
    ...crud.state
  }),
  getters: {
    ...crud.getters,
    hasHistory: () => true,
    contentType: (state, getters, rootState, rootGetters) => rootGetters['accounts/contenttypes/getByName']('patzu.order').id,
    exportActions: () => [
      {
        name: 'Bestellungseinheiten',
        path: 'patzu/orderunits/export/'
      }
    ],
    filters (state, getters, rootState, rootGetters) {
      return [
        {
          name: 'Jahre',
          options: rootGetters.years.map(y => { return { id: y, value: y } }),
          field: 'measure_year'
        },
        {
          name: 'Kategorien',
          options: rootGetters['patzu/hospitals/categories'],
          field: 'hospital__category'
        },
        {
          name: 'Status',
          options: rootGetters['patzu/orderunits/orderStates'],
          field: 'units__state'
        }
      ]
    },
    fields (state, getters, rootState, rootGetters) {
      return [
        {
          text: 'Status',
          value: 'status',
          readOnly: true,
          hideCreate: true,
          hideEdit: true,
          width: 200,
          renderItem: (value) => {
            const states = rootGetters['patzu/orderunits/orderStates']
            var tmp = ''
            value.forEach(v => {
              const state = states.filter(s => s.id === v.state)[0]
              tmp = tmp + `<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white ${state.color} rounded">${state.value}</span>`
            })
            return tmp
          }
        },
        { text: 'Messung', value: 'measure_id', type: 'rel_select', getterPath: 'patzu/measures', hideList: true },
        {
          text: 'Spital',
          value: 'hospital_id',
          type: 'rel_select',
          getterPath: 'patzu/hospitals',
          hideList: true,
          hideCreate: (item) => {
            if (item && item.measure_id) {
              return false
            }
            return true
          },
          query: (item) => { return item && item.measure_id ? { measures__id: item.measure_id } : null }
        },
        {
          text: 'Spital',
          value: 'hospital.name',
          readOnly: true,
          hideCreate: true,
          hideEdit: true,
          showSelect: true,
          width: -1
        },
        {
          text: 'Kategorie',
          value: 'hospital.category_text',
          valueCalc: 'cat',
          readOnly: true,
          hideCreate: true,
          hideEdit: true,
          showSelect: true,
          width: 250
        },
        {
          text: 'Anq-Id',
          value: 'hospital.anqid',
          readOnly: true,
          hideCreate: true,
          hideEdit: true,
          showSelect: true,
          width: 80
        },
        {
          text: 'Druckversion',
          value: 'print_type',
          width: 250,
          type: 'select',
          hideList: true,
          showSelect: true,
          options: [
            { id: 1, value: 'Option 1: Nicht konfektioniert' },
            { id: 2, value: 'Option 2: Konfektioniert' },
            { id: 3, value: 'Option 3: Konfektioniert und kuvertiert' },
            { id: 4, value: 'Option 4: Konfektioniert, kuvertiert und zugeklebt' },
            { id: 5, value: 'Zusatzoption 5: Direktversand der Druckunterlagen (kostenpflichtig)' }
          ]
        },
        { text: 'Jahr', value: 'measure.year', order: 'measure__start_date', type: 'integer', width: 60, showSelect: true, hideEdit: true, hideCreate: true },
        {
          text: 'Begleitschreiben',
          value: 'cover_letter_state',
          order: 'cover_letter_state',
          width: 120,
          type: 'select',
          showSelect: true,
          options: [
            { id: 1, value: 'Pdf' },
            { id: 2, value: 'Tool' },
            { id: 3, value: 'Keines' }
          ]
        },
        { text: 'Auswertungseinheiten', canAdd: true, relationField: 'order', getterPath: 'patzu/orderunits', value: 'units', order: 'units_count', type: 'component', componentName: 'OrderUnits', width: 250, hideCreate: true, hideList: true },
        { text: i18n.t('created'), value: 'created', format: 'DateTime', readOnly: true, hideCreate: true, hideEdit: true, width: 160, showSelect: true },
        { text: i18n.t('modified'), value: 'modified', format: 'DateTime', readOnly: true, hideCreate: true, hideEdit: true, width: 160, showSelect: true }
      ]
    }
  },
  mutations: {
    ...crud.mutations
  },
  actions: {
    ...crud.actions
  }
}
