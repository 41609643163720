<template>
    <div class="flex items-center justify-center h-screen bg-gradient-to-t from-green-900 to-green-500"
     :class="{'from-blue-900 to-blue-500': isRedirect || isRedirectApp }">
   <div class="w-full max-w-sm -mt-32">
     <div class="text-center text-4xl md:text-5xl mb-1 md:mb-5 text-white tracking-wide flex items-center justify-center">
       <i>{{title}}</i>
       <img src="../assets/images/logo.png" alt="Image" :style="{ width: 100 + 'px', height: 'auto', margin: '20px'}" />
    </div>
     <LanguageSwitcher class="justify-end"></LanguageSwitcher>

  <form v-if="!pwForget" class="bg-white dark:bg-neutral-800 shadow-md rounded px-8 pt-6 pb-8 mb-4" @submit.prevent="login" autocomplete="off">
    <template v-if="!showCode">
    <div class="mb-4">
      <label for="email">
        {{$t('email')}}
      </label>
      <input v-model="email" id="email" type="email" :placeholder="$t('email')" :class="[isValidEmail ? '' : 'focus:border-red-500 border-red-500 dark:border-red-800 bg-red-200 dark:bg-red-900', '']">
    </div>
    <div class="mb-4">
      <label for="password">
        {{$t('password')}}
      </label>
      <input v-model="password" id="password" type="password" placeholder="******************">
    </div>
    </template>
    <div v-else class="mb-2">
      <p class="mb-4">
        {{$t('code-hint')}}
      </p>
      <label for="code">
        {{$t('code')}}
      </label>
       <input required v-model="code" id="single-factor-code-text-field" autocomplete="one-time-code" ref="code"/>
     </div>
     <a v-if="showCode" class="mb-4 inline-block align-baseline font-bold text-sm text-neutral-600 hover:text-neutral-800" href="#" @click="resendCode()">
        {{$t('resend-code')}}
      </a>
    <div class="flex items-center justify-between">
      <button type="submit" class="w-1/2 bg-primary" :class="{'hover:bg-primary cursor-not-allowed' : !canLogin}" :disabled="!canLogin">
        {{$t('login')}}
      </button>
      <a v-if="!showCode" class="inline-block align-baseline ml-2 font-bold text-sm text-neutral-600 hover:text-neutral-800" href="#" @click="pwForget = true">
        {{$t('forgotPassword')}}
      </a>
      <a v-if="showCode" class="inline-block align-baseline font-bold text-sm text-neutral-600 hover:text-neutral-800" href="#" @click="showCode = false">
        {{$t('back')}}
      </a>
    </div>

    <span class="block my-2 w-full text-center opacity-80">{{ $t('or-sign-in') }}</span>

    <HINLogin :samlRequest='this.samlRequest' />
  </form>

  <template v-if="pwForget">
   <form v-if="!pwForgetSent" class="bg-white dark:bg-neutral-700 shadow-md rounded px-8 pt-6 pb-8 mb-4" @submit.prevent="sendPwForget">
     <p v-if="pwForget" class="mb-2">
      {{$t('forgotPasswordHint')}}
     </p>
     <label for="email">
      {{$t('email')}}
     </label>
     <input required v-model="email" type="email" :placeholder="$t('email')" :class="[isValidEmail ? '' : 'focus:border-red-500 border-red-500 bg-red-200', '']" />
     <button type="submit" class="w-full my-2 bg-primary"  :disabled="!isValidEmail || this.email === ''">{{$t('send')}}</button>
     <button @click="pwForget = false" class="w-full">{{$t('back')}}</button>
    </form>
    <div v-else class="bg-white dark:bg-neutral-700 shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h3 class="mb-2">{{$t('sentTitle')}}</h3>
      <p class="mb-2" v-html="$t('sentForgotPasswordHint', { email: email })">
      </p>
      <button  @click="pwForget = false;pwForgetSent = false;email=''" class="w-full">{{$t('back')}}</button>
    </div>
  </template>
  <Footer></Footer>
</div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ApiClient from '../assets/js/ApiClient.js'
import LanguageSwitcher from '../components/LanguageSwitcher.vue'
import Footer from '@/components/Footer.vue'
import HINLogin from '../components/HINLogin.vue'

export default {
  components: {
    LanguageSwitcher,
    Footer,
    HINLogin
  },
  data () {
    return {
      response: null,
      email: '',
      password: '',
      code: '',
      pwForget: false,
      pwForgetSent: false,
      showCode: false,
      isLoginBtnPressed: false,
      samlRequest: ''
    }
  },
  computed: {
    ...mapGetters({
      apps: 'apps/apps/all'
    }),
    canLogin () {
      return this.email !== '' && this.password !== '' && (this.code !== '' || !this.showCode) && this.isValidEmail && !this.isLoginBtnPressed
    },
    title () {
      if (this.isRedirect) {
        const app = this.apps.find(a => {
          return (a.url !== '' && decodeURIComponent(this.$route.query.redirect.replace('stage.', '')).includes(a.url)) || (a.path !== '' && decodeURIComponent(this.$route.query.redirect.replace('stage.', '')).includes(a.path))
        })
        if (app) {
          return this.$t(`${app.name.toLowerCase()}.title`)
        }
      }
      const app = this.isRedirectApp
      if (app) {
        return this.$t(`${app.name.toLowerCase()}.title`)
      }
      return 'Psydata'
    },
    isRedirect () {
      return this.$route.query.redirect
    },
    isRedirectApp () {
      return this.apps.find(a => {
        return a.name && this.$route.query.app && this.$route.query.app.toLowerCase().includes(a.name.toLowerCase())
      })
    },
    isValidEmail () {
      return this.email === '' || (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email))
    }
  },
  methods: {
    ...mapActions(['updateAccessToken', 'updateRefreshToken', 'initialize']),
    sendPwForget () {
      ApiClient
        .post('password/reset/', {
          email: this.email.toLowerCase(),
          referrer_url: process.env.VUE_APP_URL,
          lang: this.$root.$i18n.locale
        })
      this.pwForgetSent = true
    },
    doLogin (data) {
      this.updateAccessToken(data.access)
      this.updateRefreshToken(data.refresh)
      this.initialize()

      if (this.$route.query.redirect) {
        if (this.$route.query.redirect.includes('apps.w-hoch2')) {
          window.location.replace(decodeURIComponent(this.$route.query.redirect))
        } else {
          const form = document.createElement('form')
          form.method = 'POST'
          form.action = decodeURIComponent(this.$route.query.redirect)
          const input = document.createElement('input')
          input.type = 'hidden'
          input.name = 'access_token'
          input.value = data.access
          form.appendChild(input)
          document.body.appendChild(form)
          form.submit()
        }
      } else {
        this.$router.replace('/')
      }
    },
    resendCode () {
      ApiClient.post('api/2fa/token-resend/', { email: this.email.toLowerCase(), password: this.password }).then((response) => {
        this.$store.dispatch('addNotification', { title: this.$t('succeed'), message: this.$t('code-sent') })
      }).catch(err => {
        this.$store.dispatch('addNotification', { title: this.$t('error'), message: err.response.data, error: true })
      })
    },
    async login () {
      this.isLoginBtnPressed = true
      const getThis = () => {
        return this
      }
      await (function runningLogin () {
        const promise = new Promise((resolve) => {
          if (getThis().showCode) {
            getThis().isLoginBtnPressed = false
            ApiClient.post('api/2fa/token-verify/', { email: getThis().email.toLowerCase(), password: getThis().password, token: getThis().code }).then((response) => {
              const data = response.data
              getThis().doLogin(data)
              resolve('done')
            }).catch((err) => {
              getThis().$store.dispatch('addNotification', { title: getThis().$t('error'), message: err.response.data, error: true })
            })
          } else {
            ApiClient.post('api/token/', { email: getThis().email.toLowerCase(), password: getThis().password }).then((response) => {
              if (response.status === 206) {
                getThis().showCode = true
                getThis().$nextTick(() => {
                  getThis().$refs.code.focus()
                })
                getThis().isLoginBtnPressed = false
              } else {
                const data = response.data
                getThis().doLogin(data)
              }
              resolve('done')
            }).catch((err) => {
              if (err.response && err.response.status === 401) {
                getThis().$store.dispatch('addNotification', { title: getThis().$t('error'), message: getThis().$t('login-failed'), error: true })
                getThis().isLoginBtnPressed = false
              } else {
                getThis().$store.dispatch('addNotification', { title: getThis().$t('error'), message: err.reponse.data.error, error: true })
                getThis().isLoginBtnPressed = false
              }
            }).finally(() => {
              // getThis().isLoginBtnPressed = false
            })
          }
        })
        promise.then(() => {
          localStorage.setItem('usedLoginRoute', window.location.pathname)
          // getThis().isLoginBtnPressed = false
        }).catch()
      })()
    }
  },
  async created () {
    const params = new URLSearchParams(window.location.search)
    const code = params.get('code')
    if (code) {
      try {
        const response = await ApiClient.post('exchange_code_for_tokens/', { code })
        this.doLogin(response.data)
      } catch (error) {
        this.error = 'Token exchange failed'
      }
    }
  },
  async mounted () {
    await this.$store.dispatch('apps/apps/load')
    if (this.$route.query.email) {
      this.email = this.$route.query.email
    }
    ApiClient.get('index/?sso')
      .then(response => { this.samlRequest = response.data })
      .catch((err) => console.log('ERROR CALLING SSO', err))
  }
}
</script>
