<style scoped>
.icons {
    @apply text-sm font-bold border-2 mr-1 p-1 h-8 w-8 rounded-lg flex items-center justify-center  cursor-pointer;
  }

  [contenteditable] {
    @apply pl-1;
  }
</style>
<template>
 <div class="w-full h-full relative group flex">
    <div v-if="!isPreview && isAdminOrStaff">
      <div
        class="absolute z-50 hidden group-hover:flex"
        :class="[editChart.type === 'text' ? 'right-1 top-0' : 'top-3 left-3']"
        >
        <div
          v-if="editChart.type === 'text'"
          v-tooltip="$t('font-smaller')"
          class="icons bg-sky-600 rounded-full flex items-center justify-center hover:sky-red-800 border-sky-700 cursor-pointer text-white"
          @click="updateFontSize(-1)"
          >
          <span style="font-size:0.7em">Aa</span>
        </div>
        <div
          v-if="editChart.type === 'text'"
          v-tooltip="$t('font-bigger')"
          class="icons bg-sky-600 rounded-full flex items-center justify-center hover:bg-sky-800 border-sky-700 cursor-pointer text-white"
          @click="updateFontSize(1)"
          >
          <span class="font-base">Aa</span>
        </div>
        <div
          v-tooltip="$t('repos-chart')"
          class="vue-draggable-handle icons cursor-move bg-emerald-600 hover:bg-emerald-800 border-green-700 "
        >
          <svg class="w-4 fill-current text-white"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12l-6-5v4h-5v-5h4l-5-6-5 6h4v5h-5v-4l-6 5 6 5v-4h5v5h-4l5 6 5-6h-4v-5h5v4z"/></svg>
        </div>
        <div
          v-if="editChart.type === 'text'"
          v-tooltip="$t('delete')"
          class="icons bg-red-600 rounded-full flex items-center justify-center hover:bg-red-800 border-red-700 cursor-pointer"
          @click="$emit('on-delete-chart', chart)"
          >
          <svg class="fill-current text-white w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z"/></svg>
        </div>
      </div>
    </div>
      <div
        v-if="editChart.type === 'text'"
        :style="'font-size: '+fontSize+'rem'"
        class="w-full"
      >
      <!-- This is Titles text(above the chart) -->
        <div class="font-bold h-full overflow-hidden"
          :key="editChart.i"
          contenteditable="true"
          @blur="updateText"
          v-html="currentText">
        </div>
      </div>
      <div
        v-if="editChart.type === 'tableChart'"
        class="border-2 p-2 h-full w-full bg-white dark:bg-chart-dark dark:border-neutral-600 overflow-y-auto"
      >
        <TableChart
          ref="grandchildComponent"
          :chart="editChart"
          :language="language"
          :isEditedChart="isEditedChart"
          @on-change-chart="updateType"
          @on-update-chart-view="updateChartView"
          @on-update-chart-views="updateChartViews"
        />
      </div>

      <template v-if="restrictToFspAnq == true">

        <BarChartFSP
        v-if="(editChart.type === 'bar')"
        ref="grandchildComponent"
        :chart="editChart"
        :annotations="annotations"
        :isEditedChart="isEditedChart"
        :print="print"
        :readOnly="readOnlyView"
        :language="language"
        @on-change-chart="updateType"
        @on-update-chart-view="updateChartView"
        @on-update-chart-views="updateChartViews"
        @on-update-chart-spinner-start="startSpinner"
        @on-update-chart-spinner-stop="stopSpinner"
      ></BarChartFSP>

      <BarChartFSP
        v-if="(editChart.type === 'stacked')"
        ref="grandchildComponent"
        :chart="editChart"
        :annotations="annotations"
        :isEditedChart="isEditedChart"
        :print="print"
        :readOnly="readOnlyView"
        :stacked="true"
        :language="language"
        @on-change-chart="updateType"
        @on-update-chart-view="updateChartView"
        @on-update-chart-views="updateChartViews"
        @on-update-chart-spinner-start="startSpinner"
        @on-update-chart-spinner-stop="stopSpinner"
      ></BarChartFSP>

      <BarChartFSP
        v-if="(editChart.type === 'stacked-full')"
        ref="grandchildComponent"
        :chart="editChart"
        :annotations="annotations"
        :isEditedChart="isEditedChart"
        :print="print"
        :readOnly="readOnlyView"
        :language="language"
        :stackedFull="true"
        @on-change-chart="updateType"
        @on-update-chart-view="updateChartView"
        @on-update-chart-views="updateChartViews"
        @on-update-chart-spinner-start="startSpinner"
        @on-update-chart-spinner-stop="stopSpinner"
      ></BarChartFSP>

      <BoxPlotFSP
        v-if="editChart.type === 'boxPlot'"
        :ref="`chart_${editChart.i}`"
        :chart="editChart"
        :annotations="annotations"
        :isEditedChart="isEditedChart"
        :print="print"
        :readOnly="readOnlyView"
        :language="language"
        @on-update-chart-view="updateChartView"
        @on-update-chart-views="updateChartViews"
        @on-change-chart="updateType"
        @on-update-chart-spinner-start="startSpinner"
        @on-update-chart-spinner-stop="stopSpinner"
      ></BoxPlotFSP>

      <PyramidChartFSP
        v-if="editChart.type === 'pyramid'"
        :ref="`chart_${editChart.i}`"
        :chart="editChart"
        :annotations="annotations"
        :isEditedChart="isEditedChart"
        :print="print"
        :readOnly="readOnlyView"
        :language="language"
        @on-update-chart-view="updateChartView"
        @on-update-chart-views="updateChartViews"
        @on-change-chart="updateType"
        @on-update-chart-spinner-start="startSpinner"
        @on-update-chart-spinner-stop="stopSpinner"
      ></PyramidChartFSP>

      </template>

      <template v-else>
        <BarChart
        v-if="(editChart.type === 'bar')"
        ref="grandchildComponent"
        :chart="editChart"
        :annotations="annotations"
        :isEditedChart="isEditedChart"
        :print="print"
        :readOnly="readOnlyView"
        :language="language"
        @on-change-chart="updateType"
        @on-update-chart-view="updateChartView"
        @on-update-chart-views="updateChartViews"
        @on-update-chart-spinner-start="startSpinner"
        @on-update-chart-spinner-stop="stopSpinner"
      ></BarChart>

      <BarChart
        v-if="(editChart.type === 'stacked')"
        ref="grandchildComponent"
        :chart="editChart"
        :annotations="annotations"
        :isEditedChart="isEditedChart"
        :print="print"
        :readOnly="readOnlyView"
        :stacked="true"
        :language="language"
        @on-change-chart="updateType"
        @on-update-chart-view="updateChartView"
        @on-update-chart-views="updateChartViews"
        @on-update-chart-spinner-start="startSpinner"
        @on-update-chart-spinner-stop="stopSpinner"
      ></BarChart>

      <BarChart
        v-if="(editChart.type === 'stacked-full')"
        ref="grandchildComponent"
        :chart="editChart"
        :annotations="annotations"
        :isEditedChart="isEditedChart"
        :print="print"
        :readOnly="readOnlyView"
        :language="language"
        :stackedFull="true"
        @on-change-chart="updateType"
        @on-update-chart-view="updateChartView"
        @on-update-chart-views="updateChartViews"
        @on-update-chart-spinner-start="startSpinner"
        @on-update-chart-spinner-stop="stopSpinner"
      ></BarChart>

      <MeanChart
        key="radar_chart"
        v-if="editChart.type === 'radar'"
        :chart="editChart"
        :annotations="annotations"
        :isEditedChart="isEditedChart"
        :print="print"
        :readOnly="readOnlyView"
        :language="language"
        @on-change-chart="updateType"
        @on-update-chart-view="updateChartView"
        @on-update-chart-views="updateChartViews"
        @on-update-chart-spinner-start="startSpinner"
        @on-update-chart-spinner-stop="stopSpinner"
      ></MeanChart>

      <MeanChart
        key="mean_chart"
        v-if="editChart.type === 'mean'"
        :chart="editChart"
        :annotations="annotations"
        :isEditedChart="isEditedChart"
        :print="print"
        :readOnly="readOnlyView"
        :language="language"
        @on-change-chart="updateType"
        @on-update-chart-view="updateChartView"
        @on-update-chart-views="updateChartViews"
        @on-update-chart-spinner-start="startSpinner"
        @on-update-chart-spinner-stop="stopSpinner"
      ></MeanChart>

      <div
        v-if="chart.type === 'timeline'"
        class="p-1 inline h-full w-full"
      >
        <timeline-chart
          :chart="editChart"
          :annotations="annotations"
          :isEditedChart="isEditedChart"
          :print="print"
          :readOnly="readOnlyView"
          :language="language"
          @on-change-chart="updateType"
        ></timeline-chart>
      </div>

      <IkaChart
      v-if="editChart.type === 'ika'"
      :chart="editChart"
      :annotations="annotations"
      :isEditedChart="isEditedChart"
      :print="print"
      :readOnly="readOnlyView"
      :language="language"
      @on-change-chart="updateType"
      @on-update-chart-view="updateChartView"
      @on-update-chart-views="updateChartViews"
      ></IkaChart>

      <LikertChart
      v-if="editChart.type === 'likert'"
      :ref="`chart_${editChart.i}`"
      :chart="editChart"
      :annotations="annotations"
      :isEditedChart="isEditedChart"
      :print="print"
      :readOnly="readOnlyView"
      :language="language"
      @on-update-chart-view="updateChartView"
      @on-update-chart-views="updateChartViews"
      @on-change-chart="updateType"
      ></LikertChart>

      <BoxPlot
        v-if="editChart.type === 'boxPlot'"
        :ref="`chart_${editChart.i}`"
        :chart="editChart"
        :annotations="annotations"
        :print="print"
        :readOnly="readOnlyView"
        :language="language"
        @on-update-chart-view="updateChartView"
        @on-update-chart-views="updateChartViews"
        @on-change-chart="updateType"
        @on-update-chart-spinner-start="startSpinner"
        @on-update-chart-spinner-stop="stopSpinner"
      />

      <PyramidChart
        v-if="editChart.type === 'pyramid'"
        :ref="`chart_${editChart.i}`"
        :chart="editChart"
        :annotations="annotations"
        :print="print"
        :readOnly="readOnlyView"
        :language="language"
        @on-update-chart-view="updateChartView"
        @on-update-chart-views="updateChartViews"
        @on-change-chart="updateType"
        @on-update-chart-spinner-start="startSpinner"
        @on-update-chart-spinner-stop="stopSpinner"
      />

      </template>

      <LoadingSpin v-if="isSpinning">
      </LoadingSpin>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import utilsMixin from '@/mixins/utils'
import LikertChart from '@/components/charts/LikertChart'
import MeanChart from '@/components/charts/MeanChart'
import BarChart from '@/components/charts/BarChart'
import IkaChart from '@/components/charts/IkaChart'
import BoxPlot from '@/components/charts/BoxPlot.vue'
import PyramidChart from '@/components/charts/PyramidChart.vue'
import TimelineChart from '@/components/charts/TimelineChart.vue'
import BarChartFSP from '@/components/chartsFSP/BarChart'
import BoxPlotFSP from '@/components/chartsFSP/BoxPlot.vue'
import PyramidChartFSP from '@/components/chartsFSP/Pyramid.vue'
import TableChart from '@/components/chartsFSP/TableChart.vue'
import LoadingSpin from '@/components/LoadingSpin.vue'

export default {
  name: 'ChartBase',
  mixins: [utilsMixin],
  props: {
    hasTabs: {
      type: Boolean,
      default: true
    },
    isAdminOrStaff: {
      type: Boolean,
      default: true
    },
    language: {
      type: String,
      default: 'de'
    },
    isEditedChart: {
      type: Boolean,
      default: false
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    chart: {
      type: Object,
      required: true
    },
    print: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnlyView: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LikertChart,
    BarChart,
    MeanChart,
    IkaChart,
    TimelineChart,
    BoxPlot,
    PyramidChart,
    BarChartFSP,
    BoxPlotFSP,
    PyramidChartFSP,
    LoadingSpin,
    TableChart
  },
  data: function () {
    return {
      editChart: this.chart,
      currentLang: this.language,
      isSpinning: false
    }
  },
  computed: {
    ...mapGetters({
      languages: 'results/evaluationStore/languagesString',
      currentEvaluation: 'results/evaluations/detailItem'
      // isSpinning: 'isSpinning'
    }),
    restrictToFspOnly () {
      const current = this.currentEvaluation.measures
      return current.includes(65) || current.includes(66) || current.includes(101)
    },
    restrictToFspAnq () {
      const current = this.currentEvaluation.measures
      return current.includes(65) || current.includes(66) || current.includes(101) || current.includes(113) || current.includes(61) || current.includes(62) || current.includes(63)
    },
    path () {
      return `${this.$route.params.app}/${this.$route.params.collection}`
    },
    currentText () {
      if (this.language === 'fr') {
        return this.editChart.text_fr
      } else if (this.language === 'it') {
        return this.editChart.text_it
      }
      return this.editChart.text
    },
    fontSize () {
      return this.editChart.fontSize
    },
    annotations () {
      if (this.chart.filters === undefined || this.chart.filters.length === 0) {
        return {}
      }
      var grouped = this.groupBy(this.chart.filters, 'key')
      var texts = []
      var y = 25
      texts.push(
        {
          x: 5,
          y: y,
          text: `${this.$t('filter')}`,
          appendTo: '.apexcharts-annotations',
          fontWeight: 'bold'
        }
      )
      y = y + 15
      // THIS IS DISPLAYING FILTER ON CHARTS, WHEN YOU FILTER BY DATE OR BY SEX OR BY AGE
      Object.keys(grouped).forEach(key => {
        var f = grouped[key]

        if (f[0].type === 'date_range') {
          const until = dayjs().format('DD.MM.YYYY')
          const from = dayjs().subtract(f[0].daysBack, 'day').format('DD.MM.YYYY')
          if (f[0].daysBack) {
            texts.push(
              {
                x: 5,
                y: y,
                text: `${f[0].keyLabel} = ${this.$t('last')} ${f[0].daysBack}  ${this.$t('days')} (${from} - ${until})`,
                appendTo: '.apexcharts-annotations',
                fontWeight: 'normal'
              }
            )
          } else {
            const translation = this.$t(f[0].keyLabel)
            const fromExactDate = dayjs(f[0].from).format('DD.MM.YYYY')
            let untilExactDate = until
            // check if value in input is not null or undefined because that you can pick there "today", then transform this value to date
            if (f[0].until) {
              untilExactDate = dayjs(f[0].until).format('DD.MM.YYYY')
            }
            texts.push(
              {
                x: 5,
                y: y,
                text: `${translation || f[0].keyLabel} = ${fromExactDate} - ${untilExactDate}`,
                appendTo: '.apexcharts-annotations',
                fontWeight: 'normal'
              }
            )
          }
        } else {
          const translation = this.$t(f[0].questionForFilterInChart)
          texts.push(
            {
              x: 5,
              y: y,
              text: `${translation || f[0].key.charAt(0).toUpperCase() + f[0].key.slice(1)} = ${f.map(v => v.value).join(', ')}`,
              appendTo: '.apexcharts-annotations',
              fontWeight: 'normal'
            }
          )
        }
        y = y + 15
      })
      return { texts: texts }
    }
  },
  methods: {
    ...mapActions({
      loadDataByFilter: 'results/evaluationStore/loadDataByFilter'
    }),
    startSpinner () {
      this.isSpinning = true
    },
    stopSpinner () {
      this.isSpinning = false
    },
    childMethod () {
      // Access the method in GrandchildComponent using $refs
      this.$refs.grandchildComponent.setupData()
    },
    updateText (e) {
      if (this.language === 'fr') {
        this.editChart.text_fr = e.target.innerHTML
      } else if (this.language === 'it') {
        this.editChart.text_it = e.target.innerHTML
      } else {
        this.editChart.text = e.target.innerHTML
      }

      this.$emit('on-update-chart', this.editChart)
    },
    updateFontSize (value) {
      this.$set(this.editChart, 'fontSize', this.editChart.fontSize + (value * 0.1))
      this.$emit('on-update-chart', this.editChart)
    },
    updateType (type) {
      this.editChart.type = type
      this.editChart.viewSetting = []

      if (['boxPlot', 'tableChart', 'pyramid'].includes(type)) {
        const dData = this.editChart.data[0]
        this.$set(this.editChart, 'title', dData.label)
        this.$set(this.editChart, 'title_fr', dData.label_fr)
        this.$set(this.editChart, 'title_it', dData.label_it)
      }
      this.$emit('on-update-chart', this.editChart)
    },
    updateChartView (name, args, bool) {
      if (name === 'benchmark') {
        if (bool || !this.editChart.viewSetting?.length) {
          if (bool) {
            this.editChart.viewSetting = []
          }
          if (args.length) {
            this.editChart.viewSetting.push(...args)
          }
        } else {
          this.editChart.viewSetting = []
        }
      }
    },
    updateChartViews (name) {
      const viewSettings = this.editChart.viewSettings
      const index = viewSettings?.indexOf(name)
      if (index > -1) {
        viewSettings.splice(index, 1)
      } else {
        viewSettings.push(name)
      }
      this.$emit('on-update-chart', this.editChart)
    },
    onPasteText (e) {
      const paste = (e.clipboardData || window.clipboardData).getData('text/plain')
      const selection = window.getSelection()
      const range = selection.getRangeAt(0)
      const startContainer = range.startContainer
      const startOffset = range.startOffset
      const endContainer = range.endContainer
      const endOffset = range.endOffset

      const textBeforeCursor = startContainer.textContent.substring(0, startOffset)
      const textAfterCursor = endContainer.textContent.substring(endOffset)

      startContainer.textContent = textBeforeCursor + paste + textAfterCursor

      // move the cursor to the end of the pasted text
      var newOffset = startOffset + paste.length
      if (newOffset > startContainer.length) {
        newOffset = startContainer.length
      }

      const newRange = document.createRange()
      if (startContainer.nodeType === Node.TEXT_NODE) {
        newRange.setStart(startContainer, newOffset)
        newRange.setEnd(startContainer, newOffset)
      } else {
        newRange.setStartBefore(startContainer.childNodes[newOffset])
        newRange.setEndBefore(startContainer.childNodes[newOffset])
      }

      selection.removeAllRanges()
      selection.addRange(newRange)

      this.$emit('on-update-chart', this.editChart)
    }
  },
  mounted () {
    if (this.chart.type !== 'text' && this.chart.filters) {
      this.loadDataByFilter({ chartFilter: this.chart.filters, chartId: this.chart.i })
    }
  },
  watch: {
    chart: {
      handler (val) {
        this.editChart = this.chart
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
