<template>
  <div @click="enabled || inProgress ? onClick() : null"
    class="text-lg rounded-lg   p-4 w-full mr-1 text-center flex items-center justify-center"
    :class="[inProgress ? 'bg-orange-400 text-white hover:bg-orange-300 cursor-pointer': enabled ? 'bg-sky-500 text-white hover:bg-sky-300 cursor-pointer' : 'bg-slate-100 text-slate-900']"
  >

      <svg v-if="!inProgress && !enabled" class="fill-current text-green-600 w-6 h-6 mr-2" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" xmlns="http://www.w3.org/2000/svg"><path d="m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm-5.049 10.386 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z" fill-rule="nonzero"/></svg>
      <i  v-if="inProgress" class="material-icons">pause</i>
      <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    inProgress: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>
