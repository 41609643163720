import ApiClient from '@/assets/js/ApiClient.js'

export default {
  namespaced: true,
  state: () => ({
    fId: null,
    gender: null,
    age: null,
    random: null,
    resultsSelf: [],
    resultsExternal: [],
    language: 'de',
    hospital: null
  }),
  getters: {
    fId: state => state.fId,
    resultsSelf: state => state.resultsSelf,
    resultsExternal: state => state.resultsExternal,
    language: state => state.language,
    hospital: state => state.hospital,
    age: state => state.age,
    gender: state => state.gender,
    random: state => state.random
  },
  mutations: {
    setResultsSelf (state, results) {
      state.resultsSelf = results
    },
    setHospital (state, hospital) {
      state.hospital = hospital
    },
    setResultsExternal (state, results) {
      state.resultsExternal = results
    },
    setLanguage (state, language) {
      state.language = language
    },
    setFid (state, { fId }) {
      state.fId = fId
    },
    setAge (state, age) {
      state.age = age
    },
    setGender (state, gender) {
      state.gender = gender
    },
    setRandom (state, random) {
      state.random = random
    },
    reset (state) {
      state.resultsSelf = []
      state.resultsExternal = []
      state.fId = null
      state.hospital = null
      state.random = null
      state.gender = null
      state.age = null
    }
  },
  actions: {
    load ({ commit, dispatch, state }, { fId, selfEvaluation, hospitalId = null }) {
      var prom = new Promise((resolve, reject) => {
        var path = `?selfEvaluation=${selfEvaluation}`
        if (hospitalId) {
          path = `${path}&hospitalId=${hospitalId}`
        }

        ApiClient.get(`ika/${fId}/${path}`).then(response => {
          if (response.data.length) {
            if (selfEvaluation) {
              commit('setResultsSelf', response.data)
            } else {
              commit('setResultsExternal', response.data)
            }
          }
          resolve(response.data.results)
        }).catch((err) => {
          dispatch('addNotification', { title: 'Fehler', message: err.response.data.error, error: true, permanent: true }, { root: true })
          reject(err.response.data)
        })
      })
      return prom
    },
    updateFId ({ commit, dispatch }, fId) {
      commit('setFid', { fId: fId })
      dispatch('updateCustomTitle', `FID: ${fId}`, { root: true })
    },
    updateHospital ({ commit, dispatch }, hospital) {
      commit('setHospital', hospital)
    },
    updateLanguage ({ commit }, language) {
      commit('setLanguage', language)
    },
    updateDetails ({ commit }, { gender, age, random }) {
      commit('setGender', gender)
      commit('setAge', age)
      commit('setRandom', random)
    },
    updateGender ({ commit }, gender) {
      commit('setGender', gender)
    },
    updateAge ({ commit }, age) {
      commit('setAge', age)
    },
    addToken ({ commit, dispatch, state }, { selfEvaluation, entry, age, gender, random, hospitalId = null }) {
      var prom = new Promise((resolve) => {
        var payload = { selfEvaluation: selfEvaluation, entry: entry, age: age, gender: gender, random: random }
        if (hospitalId) {
          payload.hospitalId = hospitalId
        }
        ApiClient.post(`ika/${state.fId}/`, payload).then(response => {
          resolve(response.data)
        })
      })
      return prom
    },
    reset ({ commit, dispatch }) {
      commit('reset')
      dispatch('updateCustomTitle', undefined, { root: true })
    }
  }
}
