<style scoped>
.drag-area {
  min-height: 60px;
}
.display-none {
  display: none;
}
.fix-height-chart {
  /*max-height: calc(100vh - theme('spacing[56]')) */
  max-height: calc(92% - 0px);
}
/*.fix-height-attribute1 {
  max-height: calc(100vh - theme('spacing[72]') - theme('spacing[10]'))
} */
.fix-height-attribute2 {
  max-height: calc(100vh - theme('spacing[96]') - theme('spacing[56]'))
}
.fix-height-container {
  height: calc(100vh - theme('spacing[44]'))
}
</style>
<template>
 <div class="mpointer-events-none fixed w-full h-full top-0 left-0 flex items-start z-50">
    <div class="absolute w-full h-full bg-neutral-900 bg-opacity-80"></div>

    <div class="bg-white dark:bg-neutral-800 w-11/12 mx-auto h-11/12 rounded shadow-lg z-50 overflow-y-auto my-auto">

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="text-left px-6 flex flex-col h-full">
        <div class="flex-grow relative mb-4">
          <h2 class="text-2xl font-bold">{{ $t('edit-chart') }}</h2>
          <img v-if="restrictToFspOnly == true" src="../../assets/images/fsp_logo.png" style="position:absolute;width:100px;right:0px;top:16px">
          <img v-if="restrictToAnqOnly == true" src="../../assets/images/anq_logo.png" style="position:absolute;width:100px;right:0px;top:16px">
          <div v-if="hasMultipleMeasures" class="mb-2">
              <label>{{ $t('measure') }}</label>
              <VSelectFSP v-model="measure" :options="measureSelection" :mandatory="true" @changed="measureSelected()"></VSelectFSP>
            </div>
          <draggable class="fix-height-container" :group="{name: 'Serie', put: false, pull: false}" ghostClass="display-none" draggable=".draggable">
          <div class="flex my-3 gap-2">
            <div
              @click="currentTab=1"
              class="flex items-center rounded-full px-3 py-1 text-sm cursor-pointer hover:bg-neutral-100 bg-white dark:bg-neutral-700 dark:hover:bg-neutral-600 whitespace-nowrap"
              :class="{'bg-secondary font-bold text-white hover:bg-secondary hover:text-white': currentTab===1, 'bg-benjamin-anq hover:bg-benjamin-anq': currentTab===1 && restrictToAnqOnly == true }"
            >
            {{ $t('attribute') }}
            </div>
            <div
              @click="currentTab=2"
              v-if="dimensions.length"
              class="flex items-center rounded-full px-3 py-1 text-sm cursor-pointer hover:bg-neutral-100 bg-white dark:bg-neutral-700 dark:hover:bg-neutral-600 whitespace-nowrap"
              :class="{'bg-secondary font-bold text-white hover:bg-secondary hover:text-white': currentTab===2, 'bg-benjamin-anq hover:bg-benjamin-anq': currentTab===2 && restrictToAnqOnly == true }"
            >
            {{ $t('dimensions') }}
            </div>
            <div
              @click="currentTab=3"
              class="flex items-center rounded-full px-3 py-1 text-sm cursor-pointer hover:bg-neutral-100 bg-white dark:bg-neutral-700 dark:hover:bg-neutral-600 whitespace-nowrap"
              :class="{'bg-secondary font-bold text-white hover:bg-secondary hover:text-white': currentTab===3,'bg-benjamin-anq hover:bg-benjamin-anq': currentTab===3 && restrictToAnqOnly == true }"
            >
            {{ $t('filter') }}
            </div>
          </div>
          <div class="flex w-full gap-2 bg-white dark:bg-neutral-800 h-full" style="height:92%">
              <div v-if="currentTab === 1" class="flex flex-col w-3/12" style="overflow-y:auto;height:92%">
                <div v-if="isLoading">
                 {{ $t('load-data') }}
                </div>
                <div v-else class="w-full">
                    <div v-for="(serie, name, index) in editSeries[measure]" :key="`attr_group_${index}_${name}`">
                      <div
                        v-if="openIndex === index || openIndex === -1"
                        @click="openIndex === index ? openIndex = -1 : openIndex = index"
                        class="bg-secondary hover:bg-opacity-75 text-white border-b border-neutral-400 p-2 cursor-pointer flex"
                        :class="{'border-t': index === 0, 'justify-between': openIndex === -1, 'bg-benjamin-anq': restrictToAnqOnly == true}"
                      >
                        <svg v-if="openIndex === index" xmlns="http://www.w3.org/2000/svg" class="w-4 fill-current transform rotate-180 mr-2" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
                        {{serie[0] != null ? translate(serie[0], 'title', language) : name}}
                        <svg v-if="openIndex === -1" xmlns="http://www.w3.org/2000/svg" class="w-4 fill-current" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
                      </div>
                      <draggable
                        v-if="openIndex === index"
                        class="flex flex-wrap justify-start items-center mt-2 pt-4 pb-2 px-2 border-2 border-dashed border-neutral-400 bg-neutral-100 dark:bg-neutral-700 overflow-y-auto fix-height-attribute1 scrollbar"
                        :list="serie"
                        :sort="false"
                        group="Serie"
                      >

                        <div
                          :hidden="element.hide_in_graph || element.hide_in_only"
                          class="mb-2 mr-1 px-4 py-2 bg-white dark:bg-neutral-800 cursor-move hover:bg-blue-100 dark:hover:bg-blue-900 select-none w-full border border-neutral-400 text-sm"
                          :class="[hasBenchmarkForKeyAndMeasure(element.measure.id, element.key) ? 'bg-emerald-200 dark:bg-emerald-700' : 'bg-white']"
                          v-for="(element, index) in serie"
                          :key="`attr_${index}_`"
                        >
                          <template v-if="!element.hide_in_graph && !element.hide_in_only">
                          {{ translate(element, 'label', language) }}
                          </template>
                        </div>
                      </draggable>
                    </div>
                  </div>
              </div>
              <div v-if="currentTab === 2" class="flex flex-col w-3/12" style="overflow-y:auto;height:92%">
                  <draggable
                    class="flex flex-wrap justify-start items-center mt-2 pt-4 pb-2 px-2 border-2 border-dashed border-neutral-400 bg-neutral-100 dark:bg-neutral-700 fix-height-attribute1 scrollbar"
                    :list="dimensions"
                    :sort="false"
                    group="Serie"
                  >

                    <div
                      class="mb-2 mr-1 px-4 py-2 bg-white dark:bg-neutral-800 cursor-move hover:bg-blue-100 dark:hover:bg-blue-900 select-none w-full border border-neutral-400 text-sm"
                      v-for="(dim, index) in dimensions"
                      :key="`dim_${index}_`"
                    >
                    <div class="relative flex justify-between">
                      <span>{{ dim.title }}</span>
                      <div class="cursor-help" @mouseover="infoIndex = index" @mouseleave="infoIndex = -1">
                        <svg class="fill-current dark:text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z"/></svg>
                      </div>
                      <div class="absolute left-full w-96 z-50 bg-white dark:bg-neutral-800 p-2 border rounded-md" v-if="infoIndex === index">
                        <div class="text-xs p-1 border mb-1" v-for="item in dim.keys" :key="item.key">
                          {{ translate(item, 'label', language) }}
                        </div>
                      </div>
                      </div>
                    </div>
                  </draggable>

              </div>
              <div v-if="currentTab === 1 || currentTab === 2" class="w-3/12" style="overflow-y:auto;height:92%">
                <div class="w-full h-full">
                  <div v-show="editChart.data.length === 0" class="mt-1 mb-2">
                    {{ $t('place-attributes') }}
                  </div>

                   <div v-show="editChart.data.length" class=" pb-3 pt-3">
                      <label class="text-sm">{{ $t('header') }}</label>
                      <v-input-languages
                        v-if="editChart.data.length"
                        :debounce="true"
                        :value="editChart"
                        :labelKey="'title'"
                        :languages="languagesMeasure"
                        :currentLanguage="language"
                      >
                      </v-input-languages>
                      <label class="text-sm mt-2">{{ $t('subheader') }}</label>
                      <v-input-languages
                        v-if="editChart.data.length"
                       :debounce="true"
                       :value="editChart"
                       :labelKey="'subtitle'"
                       :languages="languagesMeasure"
                       :currentLanguage="language"
                      >
                      </v-input-languages>
                    </div>

                    <div v-if="!this.hideSlicesField && (this.isBarChart || this.isTableChart)" v-show="editChart.data.length" class=" pb-3">
                      <label class="text-sm mt-2">{{ $t('function') }}</label>
                      <VSelectFSP
                        v-model="addition"
                        :value="editChart"
                        :options="editChart.type === 'tableChart' ? additionalFuncTableChart : additional"
                        :mandatory="false"
                        :pickTranslationFromJson="pickTranslationFromJson"
                        :language="language"
                        @changed="additionSelected">
                      </VSelectFSP>
                    </div>

                    <div class="font-bold text-sm text-left" v-show="editChart.data?.length">
                        {{ $t('displayed-variable') }}
                      </div>
                    <div class="px-3 py-2 border-2 border-dashed bg-neutral-100 dark:bg-neutral-700 border-neutral-400 relative drag-area w-full scrollbar overflow-y-auto fix-height-attribute2" style="min-height:84px;">
                    <draggable
                      :list="editChart.data"
                      @add="addSerie(editChart)"
                      @change="dataChanged"
                      class="drag-area z-40"
                      group="Serie">
                      <div class="py-2 pl-4 pr-2 my-1 bg-white dark:bg-neutral-800 cursor-move hover:bg-blue-100 flex items-start justify-between select-none text-sm border border-neutral-400"
                        v-for="(element, ix) in editChart.data"
                        :class="[element.measure && hasBenchmarkForKeyAndMeasure(element.measure.id, element.key) ? 'bg-emerald-200 dark:bg-emerald-700' : 'bg-white']"
                        :key="`charts_${ix}_${element.label}`">
                        <div class="flex flex-col">
                          <span> {{ translate(element, 'label', language) || translate(element, 'title', language) }} </span>
                          <span v-if="hasMultipleMeasures" class="text-xs mt-1 text-neutral-500"> {{ element.measure ? element.measure.name : 'Dimension' }} </span>
                        </div>
                        <div @click="removeSerie(editChart, ix)">
                          <svg class="w-3 cursor-pointer fill-current text-neutral-800 dark:text-neutral-400 hover:text-red-600"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>
                        </div>
                      </div>
                    </draggable>

                  </div>
                  <div class="my-3" v-if="!this.hideSplitsField && currentTab !== 2 && (editChart.data.length === 1 || editChart.data.length >= 1 && !isBarChart)">
                      <div class="flex justify-center mb-3">
                        <svg class="fill-current w-10 transform rotate-180" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M14 4l2.29 2.29-2.88 2.88 1.42 1.42 2.88-2.88L20 10V4zm-4 0H4v6l2.29-2.29 4.71 4.7V20h2v-8.41l-5.29-5.3z"/></svg>
                      </div>
                      <div class="font-bold text-sm text-left" v-show="editChart.splits?.length">
                        {{ $t('split-by') }}
                      </div>
                      <draggable class="px-3 py-3 border-2 border-dashed border-neutral-400 bg-neutral-100 dark:bg-neutral-700 relative drag-area" style="min-height:97px;"
                        @change="splitsChanged"
                        :list="editChart.splits"
                        group="Serie">
                        <div v-if="!editChart.splits.length" class="absolute top-0 left-0 w-full items-center italic text-neutral-600 dark:text-neutral-300 text-center mt-1">
                          <div class="mt-3">{{ $t('split-by') }}</div>
                        </div>
                        <div class="py-2 pl-4 pr-2 my-1 bg-white dark:bg-neutral-800 cursor-move hover:bg-blue-100 flex items-start justify-between select-none text-sm border border-neutral-400"
                          v-for="(element, ix) in editChart.splits"
                          :key="`splits_${ix}_${element?element.label:''}`">
                          {{ translate(element, 'label', language) }}
                          <div @click="removeSplit(editChart, ix)">
                            <svg class="w-3 cursor-pointer fill-current text-neutral-800 dark:text-neutral-400 hover:text-red-600"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>
                          </div>
                        </div>
                      </draggable>
                    </div>
                    <div class="my-3" v-if="!this.hideGroupsField && currentTab !== 2 && editChart.data.length >= 1">
                      <div class="flex justify-center mb-3">
                        <svg class="fill-current w-10"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.698 10.658l2.302 1.342-12.002 7-11.998-7 2.301-1.342 9.697 5.658 9.7-5.658zm-9.7 10.657l-9.697-5.658-2.301 1.343 11.998 7 12.002-7-2.302-1.342-9.7 5.657zm12.002-14.315l-12.002-7-11.998 7 11.998 7 12.002-7z"/></svg>
                      </div>

                      <div class="font-bold text-xs text-left" v-show="editChart.groups?.length">
                        {{ $t('group-by') }}
                      </div>
                      <draggable class="px-3 py-3 border-2 border-dashed border-neutral-400 bg-neutral-100 dark:bg-neutral-700 relative drag-area" style="min-height:127px;"
                        @change="groupsChanged"
                        :list="editChart.groups"
                        group="Serie">
                        <div v-if="!editChart.groups || !editChart.groups.length" class="absolute top-0 left-0 w-full items-center italic text-neutral-600 dark:text-neutral-300 text-center mt-1">
                          <div class="mt-3">{{ $t('group-by') }}</div>
                        </div>
                        <div class="py-2 pl-4 pr-2 my-1 bg-white dark:bg-neutral-700 cursor-move hover:bg-blue-100 flex items-start justify-between select-none text-sm border border-neutral-400"
                          v-for="(element, ix) in editChart.groups"
                          :key="`groups_${ix}_${element?element.label:''}`">
                          {{ translate(element, 'label', language) }}
                          <div @click="removeGroups(editChart, ix)">
                            <svg class="w-3 cursor-pointer fill-current text-neutral-800 dark:text-neutral-400 hover:text-red-600"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>
                          </div>
                        </div>
                      </draggable>
                    </div>
                </div>
              </div>
              <evaluation-filter
                class="w-1/2 p-2"
                v-if="currentTab===3"
                :series="series"
                :filters="editChart.filters"
                :measure="measure"
                :hasMultipleMeasures="hasMultipleMeasures"
                :language="language"
                :isGlobalFilter="false"
                @on-filter-changed="onFilterChanged"
              />
              <div
                :class="editChart.type==='tableChart' ? 'w-8/12' : 'flex-grow w-full'"
              >
                <Chart class="fix-height-chart"
                  v-if="editChart.data.length"
                  ref="childComponent"
                  :isEditedChart=true
                  :chart="editChart"
                  :language="language"
                  :isPreview="true"
                />
              </div>
            </div>
            </draggable>

          </div>

          <div class="flex space-x-2 mb-2">
            <button class="cancel w-1/2 mb-4" @click="$emit('on-cancel', editChart, chart.data.length === 0)">{{$t('cancel')}}</button>
            <button class="primary w-1/2 mb-4" :disabled="editChart.data.length === 0" @click="emitMultipleEvents">{{$t('ok')}}</button>
          </div>

      </div>
    </div>
 </div>
</template>

<script>
import VSelectFSP from '@/components/v-selectFSP'
import Chart from '@/components/charts/Chart'
import draggable from 'vuedraggable'
import { cloneDeep } from 'lodash'

import EvaluationFilter from '@/components/display/evaluations/EvaluationFilter.vue'
import utilsMixin from '@/mixins/utils'
import { mapGetters } from 'vuex'
import VInputLanguages from '@/components/v-input-languages'

export default {
  name: 'EditChart',
  mixins: [utilsMixin],
  props: {
    chart: {
      type: Object,
      required: true
    },
    userChart: {
      type: Object,
      required: true
    },
    dimensionsInit: {
      type: Array,
      required: true
    },
    measures: {
      type: Array,
      required: true
    },
    language: {
      type: String,
      default: 'de'
    },
    pickTranslationFromJson: {
      type: Function,
      default: () => ''
    }
  },
  components: {
    Chart,
    draggable,
    EvaluationFilter,
    VInputLanguages,
    VSelectFSP
  },
  data: function () {
    return {
      openIndex: -1,
      infoIndex: -1,
      currentTab: 1,
      editChart: cloneDeep(this.chart),
      editUserChart: cloneDeep(this.userChart),
      editSeries: {},
      measure: null,
      measureObject: null,
      dimensions: cloneDeep(this.dimensionsInit),
      additional: ['Percentage', 'Count', 'Sum', 'Mean', 'Median', 'Unique'],
      additionalFuncTableChart: ['Percentage', 'Count', 'Mean', 'Median'],
      addition: 'Percentage'
    }
  },
  computed: {
    ...mapGetters({
      measureSelect: 'results/measures/forSelection',
      measureById: 'results/measures/getById',
      languagesMeasure: 'results/evaluationStore/languages',
      currentEvaluation: 'results/evaluations/detailItem',
      hasBenchmarkForKeyAndMeasure: 'results/evaluationStore/hasBenchmarkForKeyAndMeasure',
      isLoading: 'results/evaluationStore/isLoading',
      seriesDefaultGrouped: 'results/evaluationStore/seriesDefaultGrouped'
    }),
    restrictToAnqOnly () {
      const current = this.currentEvaluation.measures
      return current.includes(113) || current.includes(61) || current.includes(62) || current.includes(63)
    },
    restrictToFspOnly () {
      const current = this.currentEvaluation.measures
      return current.includes(65) || current.includes(66) || current.includes(101)
    },
    hideSlicesField () {
      return ['timeline', 'radar', 'mean', 'likert', 'boxPlot', 'pyramid'].includes(this.editChart.type)
    },
    hideSplitsField () {
      return ['timeline', 'radar', 'mean', 'likert'].includes(this.editChart.type)
    },
    hideGroupsField () {
      return ['timeline', 'radar', 'mean', 'likert', 'boxPlot', 'pyramid'].includes(this.editChart.type)
    },
    isBarChart () {
      return ['bar', 'stacked', 'stacked-full'].includes(this.editChart.type)
    },
    isTableChart () {
      return ['tableChart'].includes(this.editChart.type)
    },
    isLikertChart () {
      return this.editChart.type === 'likert'
    },
    hasMultipleMeasures () {
      return this.measures && this.measures.length > 1
    },
    series () {
      const measure = this.measure || this.measures[0]
      return this.seriesDefaultGrouped(measure)
    },
    allAttributes () {
      return Object.values(this.series).flat()
    },
    measureSelection () {
      return this.measureSelect.filter(m => this.measures.indexOf(m.id) > -1)
    }
  },
  methods: {
    dataChanged ({ added, removed }) {
      if (this.editChart.data) {
        if (removed || this.editChart.data.length >= 2) {
          this.removeData(this.editChart, 0)
        }
      }
      this.computeTextfield()
    },
    onFilterChanged (filters) {
      this.$set(this.editChart, 'filters', filters)
      this.$emit('on-filter-changed', this.editChart)
    },
    addSerie (serie) {
      if (serie.data.length === 1) {
        const item = serie.data[0]
        if (item.keys && item.keys[0].config) {
          this.$set(serie, 'config', { ...item.keys[0].config })
        }
        if (item.config) this.$set(serie, 'config', { ...item.config })
        const dData = this.editChart.data[0]

        const translateToGermanPickedValueFromFuction = ' (' + this.pickTranslationFromJson('de', this.addition) + ')'
        const translateToFrenchPickedValueFromFuction = ' (' + this.pickTranslationFromJson('fr', this.addition) + ')'
        const translateToItalianPickedValueFromFuction = ' (' + this.pickTranslationFromJson('it', this.addition) + ')'

        this.$set(this.editChart, 'title', dData.label + translateToGermanPickedValueFromFuction)
        this.$set(this.editChart, 'title_fr', dData.label_fr + translateToFrenchPickedValueFromFuction)
        this.$set(this.editChart, 'title_it', dData.label_it + translateToItalianPickedValueFromFuction)

        this.$set(serie, 'answerKeys', { ...item.answerKeys })
        this.$set(serie, 'viewSettings', ['datalabels'])
        this.$set(serie, 'measures', [this.measure])
        this.$set(serie, 'type', serie.type || 'bar')
        // Set Timeline Attribute
        const att = this.allAttributes.find(att => att.type === 'date_range')
        this.$set(serie, 'timelineAttribute', att !== undefined ? att.key : att)
      } else if (serie.data.length >= 2) {
        if (serie.config != null) {
          const a = Object.values(serie.config)[0]
          var b = null
          if (serie.keys) {
            b = Object.values(serie.keys[0].config)[0]
          } else {
            b = Object.values(serie.config)[0]
          }

          // Check Scale
          if (a.negativeValues.length + a.positiveValues.length !== b.negativeValues.length + b.positiveValues.length) {
            this.$set(serie, 'type', 'mean')
          }
        }
        if (this.isBarChart) {
          if (serie.splits.length) {
            this.removeSplit(serie, 0)
          }
        }
      }
      if (!serie.measures.includes(this.measure)) {
        const item = serie.data[serie.data.length - 1]

        if (item.config) this.$set(serie, 'config', { ...serie.config, ...item.config })
        this.$set(serie, 'answerKeys', { ...serie.answerKeys, ...item.answerKeys })
        this.$set(serie, 'measures', [...serie.measures, this.measure])
      }
    },
    removeSerie (serie, index) {
      const removed = cloneDeep(serie.data[index])
      serie.data.splice(index, 1)
      if (removed.keys) {
        this.dimensions.unshift(removed)
      } else {
        if (this.editSeries[removed.measure.id]) {
          this.editSeries[removed.measure.id][removed.title].unshift(removed)
        }
      }

      if (this.isBarChart) {
        if (serie.splits.length > 0) {
          this.removeSplit(serie, 0)
        }
        if (serie.splits.length) {
          serie.splits = []
        }
      }

      // measure from definition if not other attribute from this measure is selected
      if (removed.keys === undefined) {
        if (serie.data.filter(d => d.measure.id === removed.measure.id).length === 0) {
          var ix = serie.measures.findIndex(f => f === removed.measure.id)
          serie.measures.splice(ix, 1)
          delete serie.answerKeys[removed.measure.id]
          if (serie.config && serie.config[removed.measure.id]) {
            delete serie.config[removed.measure.id]
          }
        }
      }
      if (serie.data.length === 0) {
        this.$set(serie, 'title', null)
        this.$set(serie, 'title_fr', null)
        this.$set(serie, 'title_it', null)
        this.$set(serie, 'subtitle', null)
        this.$set(serie, 'subtitle_fr', null)
        this.$set(serie, 'subtitle_it', null)
      } else {
        this.computeTextfield()
      }
    },
    removeData (serie, index) {
      const removed = cloneDeep(serie.data[index])
      serie.data.splice(index, 1)
      this.editSeries[this.measure][removed.title].unshift(removed)
      this.computeTextfield()
    },
    removeSplit (serie, index) {
      const removed = cloneDeep(serie.splits[index])
      serie.splits.splice(index, 1)
      this.editSeries[this.measure][removed.title].unshift(removed)
      this.computeTextfield()
    },
    removeGroups (serie, index) {
      const removed = cloneDeep(serie.groups[index])
      serie.groups.splice(index, 1)
      this.editSeries[this.measure][removed.title].unshift(removed)
      this.computeTextfield()
    },
    splitsChanged ({ added, removed }) {
      if (this.editChart.splits) {
        this.editChart.splits = this.editChart.splits.filter(function (element) {
          return element !== undefined
        })
        if (this.editChart.splits.length >= 3) {
          this.removeSplit(this.editChart, 0)
        }
      }
      if (removed) {
        this.removeSplit(this.editChart, 0)
      }
      if (added) {
        if (this.isLikertChart) {
          this.$set(this.editChart, 'type', 'mean')
        }
      } else {
        // temporarily deactivated, will have to be dealth with at a later time
        /* if ((this.editChart.splits.length === 2) && (!removed)) {
          var tmp = this.editChart.splits[1]
          this.editChart.splits[1] = this.editChart.splits[0]
          this.editChart.splits[0] = tmp
        } */
      }
      this.computeTextfield()
    },
    groupsChanged ({ added, removed }) {
      if (this.editChart.groups) {
        this.editChart.groups = this.editChart.groups.filter(function (element) {
          return element !== undefined
        })
        if (this.editChart.groups.length >= 4) {
          this.removeGroups(this.editChart, 0)
        }
      }

      if (removed) {
        this.removeGroups(this.editChart, 0)
      }
      if (added) {
        if (this.isLikertChart) {
          this.$set(this.editChart, 'type', 'mean')
        }
      } else {
        // temporarily deactivated, will have to be dealth with at a later time
        /* if ((this.editChart.groups.length === 3) && (!removed)) {
          const tmp = this.editChart.groups[1]
          const tmp0 = this.editChart.groups[0]
          this.editChart.groups[1] = this.editChart.groups[0]
          this.editChart.groups[0] = tmp
        }

        if ((this.editChart.groups.length === 2) && (!removed)) {
          const tmp = this.editChart.groups[1]
          this.editChart.groups[1] = this.editChart.groups[0]
          this.editChart.groups[0] = tmp
        } */
      }
      this.computeTextfield()
    },
    computeTextfield () {
      const dData = this.editChart.data[0]
      const dPush = this.editChart.groups
      const dSplit = this.editChart.splits
      // if (this.editChart.addition) {
      if (this.addition !== null) {
        const translateToGermanPickedValueFromFuction = ' (' + this.pickTranslationFromJson('de', this.addition) + ')'
        const translateToFrenchPickedValueFromFuction = ' (' + this.pickTranslationFromJson('fr', this.addition) + ')'
        const translateToItalianPickedValueFromFuction = ' (' + this.pickTranslationFromJson('it', this.addition) + ')'

        const dSplits = dSplit.map(_ => '- aufgeteilt nach: ' + _.label).join(' ')
        const dSplitsFr = dSplit.map(_ => '- répartis par: ' + _.label_fr).join(' ')
        const dSplitsIt = dSplit.map(_ => '- divisi per: ' + _.label_it).join(' ')
        const defaultPushes = dPush.map(_ => '- gruppiert nach: ' + _.label).join(' ')
        const defaultPushesFr = dPush.map(_ => '- regroupé par: ' + _.label_fr).join(' ')
        const defaultPushesIt = dPush.map(_ => '- raggruppati per: ' + _.label_it).join(' ')

        this.$set(this.editChart, 'title', dData.label + translateToGermanPickedValueFromFuction)
        this.$set(this.editChart, 'title_fr', dData.label_fr + translateToFrenchPickedValueFromFuction)
        this.$set(this.editChart, 'title_it', dData.label_it + translateToItalianPickedValueFromFuction)
        this.$set(this.editChart, 'subtitle', [dSplits, defaultPushes].join(' '))
        this.$set(this.editChart, 'subtitle_fr', [dSplitsFr, defaultPushesFr].join(' '))
        this.$set(this.editChart, 'subtitle_it', [dSplitsIt, defaultPushesIt].join(' '))
      }
    },
    additionSelected () {
      const check = this.additional.includes(this.addition)
      const transform = check ? this.addition : null
      this.$set(this.editChart, 'addition', transform)
      this.$refs.childComponent.childMethod()
      if (check === false) {
      }
      this.computeTextfield()
    },
    async measureSelected () {
      this.openIndex = -1
      this.measureObject = this.measureById(this.measure)
      this.$emit('on-measure-changed', this.measureObject)
    },
    removeItemFromList (item) {
      if (item.keys) {
        var ix = this.dimensions.findIndex(d => d.id === item.id)
        if (ix > -1) {
          this.dimensions.splice(ix, 1)
        }
      } else {
        const mId = item.measure.id
        if (this.editSeries[mId] && this.editSeries[mId][item.title]) {
          const index = this.editSeries[mId][item.title].findIndex(serie => serie.key === item.key)
          if (index > -1) {
            this.editSeries[mId][item.title].splice(index, 1)
          }
        }
      }
    },
    emitMultipleEvents () {
      this.$emit('on-update-chart', this.editChart)
      this.$emit('on-update-userChart', this.editUserChart)
    }
  },
  async mounted () {
    if (this.editChart.addition) {
      this.addition = this.editChart.addition
    } else {
      this.$set(this.editChart, 'addition', 'Percentage')
    }
    this.measure = this.measures[0]
  },
  watch: {
    series: {
      handler (val) {
        const measure = this.measure || this.measures[0]
        this.$set(this.editSeries, measure, cloneDeep(this.series))
        this.editChart.data.forEach(item => {
          this.removeItemFromList(item)
        })
        this.editChart.splits.forEach(item => {
          this.removeItemFromList(item)
        })
        this.editChart.groups.forEach(item => {
          this.removeItemFromList(item)
        })
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
