<template>
<div class="container mx-auto border-2 dark:border-neutral-600 shadow-xl dark:shadow-neutral-900 p-10">
  <!-- <div v-if="!user.is_staff" class="text-center">
    {{ $t('intro-ika') }}
  </div>
  <div v-else>
  -->
    <div v-if="isGenToken" class="top-40 left-52 z-50 fixed w-full h-full cursor-wait backdrop-filter backdrop-blur-sm"></div>
      <v-select v-if="user.is_staff" v-model="hospital" :options="hospitalsSelect" class="mb-2"></v-select>
      <div v-if="hospital || !user.is_staff">
      <!-- Keine FID eingegeben -->
        <div v-if="!this.fId">
          <form @submit.prevent="getResults">
            <p class="font-bold mb-4 text-center">
              {{ $t('ika.enter-fid-continue') }}
            </p>
              <input @focus="$event.target.select()" v-model="editFid" type="number" class="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-xl text-center shadow-sm placeholder-slate-400
                  focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500">
              <v-checkbox v-model="confirmed" class="p-2 mt-2">{{ $t('ika.confirm') }}</v-checkbox>
            <button type="submit" class="mt-2 w-full" :disabled="!isContinueEnabled">
              <svg v-if="isLoading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {{ $t('continue') }}
            </button>
          </form>
        </div>

        <!-- Keine Resultate für FID -->
        <div v-if="fId && resultsExternal.length === 0 && resultsSelf.length === 0">
          <p class="font-bold mb-4 text-center">
            {{ $t('ika.enter-gender-and-age') }}
          </p>
            <label>{{ $t('gender') }}</label>
            <v-select v-model="tmpGender" :options="genders" @input="updateGender(tmpGender)"></v-select>
            <label class="mt-2">{{ $t('age') }}</label>
            <input v-model="tmpAge" @focus="$event.target.select()" type="number" class="mt-1 block w-full px-3 py-2" @input="checkAge">

            <div v-if="age > 0 && gender >0">
              <h2 class="font-bold text-xl">{{ $t('measure-entry') }}</h2>
              <div class="flex my-4">
                <ika-button @click="genToken(true, true)">
                  {{ $t('ika.selfevaluation') }}
                </ika-button>
                <ika-button @click="genToken(false, true)">
                  {{ $t('ika.external-evaluation') }}
                </ika-button>
              </div>
            </div>
        </div>
        <div v-else-if="fId">
          <div>
            <h2 class="font-bold text-xl">{{ $t('measure-entry') }}</h2>
            <div class="flex my-4">
              <ika-button :inProgress="entryResultsSelfInProgress !== undefined" :enabled="!hasEntryResultsSelf" @click="genToken(true, true, entryResultsSelfInProgress)">
                {{ $t('ika.selfevaluation') }}
              </ika-button>
              <ika-button :inProgress="entryResultsExternalInProgress !== undefined" :enabled="!hasEntryResultsExternal" @click="genToken(false, true, entryResultsExternalInProgress)">
                {{ $t('ika.external-evaluation') }}
              </ika-button>
            </div>
          </div>
          <div>
            <h2 class="font-bold text-xl">{{ $t('ika.measure-exit') }}</h2>
            <div class="flex my-4">
              <ika-button :inProgress="exitResultsSelfInProgress !== undefined" :enabled="!hasExitResultsSelf" @click="genToken(true, false, exitResultsSelfInProgress)">
                {{ $t('ika.selfevaluation') }}
              </ika-button>
              <ika-button :inProgress="exitResultsExternalInProgress !== undefined" :enabled="!hasExitResultsExternal" @click="genToken(false, false, exitResultsExternalInProgress)">
                {{ $t('ika.external-evaluation') }}
              </ika-button>
            </div>
          </div>
          <div v-if="hasResults" class="mt-12 pt-4 border-t-4">
            <h2 class="font-bold text-xl">{{ $t('ika.results') }}</h2>
            <div class="flex my-4">
              <ika-button @click="showResults()">
                {{ $t('ika.view-results') }}
              </ika-button>
            </div>
          </div>
        </div>
        <div v-if="fId">
          <button class="w-full mt-5" @click="back()">{{ $t('ika.change-fid') }}</button>
        </div>
        <router-link
          class="block text-sky-600 hover:text-sky-400 text-center text-sm mt-10"
          :to="'/accounts/feedbacks/+'">
          {{$t('ika.feedback-text')}}
        </router-link>
      </div>
    <!-- </div> -->
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VSelect from '@/components/v-select'
import IkaButton from '@/components/ika/IkaButton'
import VCheckbox from '@/components/v-checkbox.vue'
export default {
  components: {
    VSelect,
    IkaButton,
    VCheckbox
  },
  data () {
    return {
      hospitals: [],
      hospital: null,
      isLoading: false,
      isGenToken: false,
      confirmed: false,
      editFid: null,
      tmpAge: 1,
      tmpGender: 0,
      genders: [
        { id: 1, value: this.$t('male') },
        { id: 2, value: this.$t('femail') },
        { id: 3, value: this.$t('divers') }
      ]
    }
  },
  computed: {
    ...mapGetters({
      fId: 'ika/results/fId',
      resultsSelf: 'ika/results/resultsSelf',
      resultsExternal: 'ika/results/resultsExternal',
      language: 'ika/results/language',
      age: 'ika/results/age',
      gender: 'ika/results/gender',
      random: 'ika/results/random',
      user: 'user'
    }),
    hospitalsSelect () {
      return this.hospitals.map(h => { return { id: h.id, value: h.name } })
    },
    isContinueEnabled () {
      return this.editFid != null && this.editFid > 0 && this.confirmed === true && this.isLoading === false
    },
    hasEntryResultsSelf () {
      return this.resultsSelf.filter(p => p.attributes.entry_exit === '1' && !p.in_progress).length > 0
    },
    entryResultsSelfInProgress () {
      return this.resultsSelf.find(p => p.attributes.entry_exit === '1' && p.in_progress)
    },
    hasEntryResultsExternal () {
      return this.resultsExternal.filter(p => p.attributes.entry_exit === '1' && !p.in_progress).length > 0
    },
    entryResultsExternalInProgress () {
      return this.resultsExternal.find(p => p.attributes.entry_exit === '1' && p.in_progress)
    },
    hasExitResultsSelf () {
      return this.resultsSelf.filter(p => p.attributes.entry_exit === '2' && !p.in_progress).length > 0
    },
    exitResultsSelfInProgress () {
      return this.resultsSelf.find(p => p.attributes.entry_exit === '2' && p.in_progress)
    },
    hasExitResultsExternal () {
      return this.resultsExternal.filter(p => p.attributes.entry_exit === '2' && !p.in_progress).length > 0
    },
    exitResultsExternalInProgress () {
      return this.resultsExternal.find(p => p.attributes.entry_exit === '2' && p.in_progress)
    },
    hasResultsEntry () {
      return this.hasEntryResultsSelf || this.hasEntryResultsExternal
    },
    hasResultsExit () {
      return this.hasExitResultsSelf || this.hasExitResultsExternal
    },
    hasResults () {
      return this.hasResultsExit || this.hasResultsEntry
    }
  },
  methods: {
    ...mapActions({
      loadResults: 'ika/results/load',
      addToken: 'ika/results/addToken',
      reset: 'ika/results/reset',
      updateFId: 'ika/results/updateFId',
      updateLanguage: 'ika/results/updateLanguage',
      updateHospital: 'ika/results/updateHospital',
      updateDetails: 'ika/results/updateDetails',
      updateAge: 'ika/results/updateAge',
      updateGender: 'ika/results/updateGender',
      startLoading: 'startLoading',
      endLoading: 'endLoading'
    }),
    checkAge () {
      if (this.tmpAge > 120) {
        this.tmpAge = 120
      }
      this.updateAge(this.tmpAge)
    },
    async getResults () {
      this.isLoading = true
      try {
        await this.loadResults({ fId: this.editFid, selfEvaluation: true, hospitalId: this.user.is_staff ? this.hospital : null })
        await this.loadResults({ fId: this.editFid, selfEvaluation: false, hospitalId: this.user.is_staff ? this.hospital : null })
        this.updateFId(this.editFid)
        // Get gender and age from results if possible
        if (this.hasEntryResultsSelf || this.entryResultsSelfInProgress) {
          this.tmpAge = parseInt(this.resultsSelf[0].attributes.age)
          this.tmpGender = parseInt(this.resultsSelf[0].attributes.gender)
          var random = parseInt(this.resultsSelf[0].attributes.random)
        } else if (this.hasEntryResultsExternal || this.entryResultsExternalInProgress) {
          this.tmpAge = parseInt(this.resultsExternal[0].attributes.age)
          this.tmpGender = parseInt(this.resultsExternal[0].attributes.gender)
          random = parseInt(this.resultsExternal[0].attributes.random)
        }
        this.updateDetails({ age: this.tmpAge, gender: this.tmpGender, random: random })
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    },
    async genToken (isSelf, isEntry, resultsProgress) {
      this.isGenToken = true
      if (resultsProgress) {
        window.location.replace(`https://survey.w-hoch2.ch/index.php/${resultsProgress.surveyId}?lang=${this.language}&token=${resultsProgress.token}&newtest=Y`)
      }
      const results = await this.addToken({ selfEvaluation: isSelf, entry: isEntry, age: this.age, gender: this.gender, random: this.random, hospitalId: this.user.is_staff ? this.hospital : null })
      window.location.replace(`https://survey.w-hoch2.ch/index.php/${results.surveyId}?lang=${this.language}&token=${results.participant.token}&newtest=Y`)
      setTimeout(() => {
        this.isGenToken = false
      }, 2000)
    },
    showResults () {
      this.updateHospital(this.user.is_staff ? this.hospital : null)
      this.$router.push({ path: '/ika/results' })
    },
    back () {
      this.tmpAge = 0
      this.tmpGender = 0
      this.reset()
    }
  },
  async mounted () {
    if (this.user.is_staff) {
      this.hospitals = await this.$store.dispatch('ika/hospitals/load', { callback: true, all: true, query: { checkPermissions: 0, state: 1, size: 1 } })
    } else {
      var data = await this.$store.dispatch('ika/hospitals/load', { callback: true, pagination: true, query: { checkPermissions: 1, state: 1, size: 1 } })
      this.updateLanguage(data.results[0].language === 2 ? 'fr' : data.results[0].language === 3 ? 'it' : 'de')
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.path !== '/ika/results') {
      this.reset()
    }
    next()
  }
}
</script>
