import Crud from '../../crud'

var crud = new Crud('patzu/addresses')

export default {
  namespaced: true,
  state: () => ({
    ...crud.state
  }),
  getters: {
    ...crud.getters,
    hasHistory: () => true,
    contentType: (state, getters, rootState, rootGetters) => rootGetters['accounts/contenttypes/getByName']('patzu.address').id,
    fields () {
      return [
        { text: 'Spital', value: 'hospital_name', width: -1, order: 'hospital', readOnly: true, hideCreate: true, hideEdit: true },
        { text: 'Spital', value: 'hospital', type: 'rel_select', getterPath: 'patzu/hospitals', hideList: true, hideEdit: true },
        { text: 'Anrede', value: 'title', type: 'select', width: 80, options: [{ id: 1, value: 'Frau' }, { id: 2, value: 'Herr' }], showSelect: true },
        { text: 'Vorname', value: 'prename', width: 100, showSelect: true },
        { text: 'Name', value: 'name', width: 100, showSelect: true },
        { text: 'Firma', value: 'company', width: 250, showSelect: true },
        { text: 'Strasse / Nr', value: 'street_nr', width: 150, showSelect: true },
        { text: 'Postfach', value: 'postbox', width: 80, showSelect: true },
        { text: 'Plz', value: 'plz', width: 60, showSelect: true },
        { text: 'Ort', value: 'place', width: 80, showSelect: true }
      ]
    }
  },
  mutations: {
    ...crud.mutations
  },
  actions: {
    ...crud.actions
  }
}
