<template>
  <div v-if="visible" class="mpointer-events-none fixed w-full h-full top-0 left-0 flex items-start z-50">
    <div class="absolute w-full h-full bg-neutral-900 opacity-50"></div>

    <div class="bg-white w-1/3 mx-auto rounded shadow-lg z-50 overflow-y-auto mt-32">

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="modal-content py-4 text-left px-6">
        <h1 class="flex-grow">{{title}}</h1>
        <p class="mt-4 mb-3 text-sm px-1">
            {{current}} von {{count}}
        </p>
         <div class="bg-neutral-300 h-5 w-full rounded-lg mb-5">
             <div class="bg-secondary h-5 rounded-lg" :style="`width: ${currentPercent}%`"></div>
         </div>
        <div class="flex justify-end space-x-2">
          <button class="cancel" @click="hide">Abbrechen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { Progress } from '@/plugins/plugins.js'

export default {
  data () {
    return {
      visible: false,
      title: '',
      count: 0,
      current: 0,
      onCancel: null
    }
  },
  computed: {
    currentPercent () {
      return this.current / this.count * 100
    }
  },
  methods: {
    hide () {
      this.onCancel()
      this.current = 0
      this.visible = false
    },
    show (params) {
      this.visible = true
      this.title = params.title
      this.count = params.count
      this.onCancel = params.onCancel
    }
  },
  beforeMount () {
    Progress.EventBus.$on('show', (params) => {
      this.show(params)
    })

    Progress.EventBus.$on('step', (params) => {
      this.current += 1
    })
  }
}

</script>
