<template>
<div class="w-full">
  <div v-if="isLoading" class="z-40 fixed w-full h-full cursor-wait backdrop-filter backdrop-blur-sm"></div>
  <component
        v-if="hasCustomItemsView"
        :items="items"
        :headers="headers"
        :is="customItemsView.split('/')[customItemsView.split('/').length - 1]"
  ></component>
  <template v-else>
    <v-table
      :headers="headers"
      :items="items"
      :selectable="isSelectable"
      :showSelect="isTrashView"
      :path="path"
      :filters="filters"
      @click="onClick"
      @select="onSelect">
    </v-table>
    <Pagination :path="path" :query="query"/>
  </template>
</div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import VTable from '@/components/v-table'
import Pagination from '@/components/Pagination'

export default {
  components: {
    'v-table': VTable,
    Pagination
  },
  data () {
    return {
      hasMounted: false
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
      query: 'query'
    }),
    isTrashView () {
      return this.$route.query.is_active === 'false'
    },
    isSelectable () {
      if (this.isTrashView) {
        return false
      }
      if (this.$store.getters[`${this.path}/isSelectable`] !== undefined) {
        return this.$store.getters[`${this.path}/isSelectable`]
      }
      return true
    },
    customItemsView () {
      return this.$store.getters[`${this.path}/customItemsView`]
    },
    hasCustomItemsView () {
      return this.customItemsView !== undefined
    },
    items () {
      var items = this.$store.getters[`${this.path}/all`]
      this.$store.commit('setTableItemsLength', this.count)
      return items
    },
    count () {
      return this.$store.getters[`${this.path}/pagination`] ? this.$store.getters[`${this.path}/pagination`].count : '...'
    },
    headers () {
      return this.$store.getters[`${this.path}/fields`]
    },
    filters () {
      return this.$store.getters[`${this.path}/filters`]
    },
    path () {
      return `${this.$route.params.app}/${this.$route.params.collection}`
    }
  },
  methods: {
    getStyleForHeader (header, index) {
      if (header.width !== null) {
        let width = ''
        if (header.width === -1) {
          width = '100%'
        } else if (header.width > 0) {
          width = header.width + 12 + 'px'
        } else {
          width = 'auto'
        }
        return { width }
      }
      return null
    },
    onClick (item) {
      if (this.$store.getters[`${this.path}/detailPath`]) {
        const path = this.$store.getters[`${this.path}/detailPath`](item)
        this.$router.push({ path: path })
      } else {
        this.$router.push({ path: `${this.$route.params.collection}/${item.id}` })
      }
    },
    onSelect (selection) {
      this.$store.commit('setSelection', selection)
    },
    registerComponents () {
      if (this.customItemsView) {
        const component = import('@/views/' + this.customItemsView + '.vue')
        const tmpName = this.customItemsView.split('/')
        const componentName = tmpName[tmpName.length - 1]
        Vue.component(componentName, () => ({
          component: component
        }))
      }
    }
  },
  mounted () {
    if (!this.hasMounted) {
      const path = `${this.path}/load`
      this.$store.dispatch(path, { query: { ...this.$route.query, ...this.query } })
      this.$store.commit('setQuery', { ...this.$route.query, ...this.query })
      this.hasMounted = true
    }
  },
  created () {
    this.registerComponents()
  },
  beforeRouteUpdate (to, from, next) {
    const query = Object.assign({}, to.query)
    this.$store.commit('setQuery', query)
    next()
  }
}
</script>
