import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
require('dayjs/locale/de')
dayjs.extend(relativeTime)
dayjs.locale('de')
export default {
  methods: {
    formatDate (date) {
      return dayjs(date).format('DD.MM.YYYY')
    },
    formatDateTime (date) {
      return date ? dayjs(date).format('DD.MM.YYYY HH:mm') : '-'
    },
    formatRelativeTime (date) {
      return dayjs(date).fromNow()
    }
  }
}
