<template>
<div class="flex flex-col">
    <div class="text-lg text-bold mb-2" v-if="!hasPendingReviews">
      {{ $t('irp.no_open_reviews') }}
    </div>
    <!-- <div class="flex flex-col self-center border-2 rounded-lg border-black p-2">
      <div class="flex flex-row gap-2">
        <section class="">
          <h2 class="font-bold">
            Allgemeine Unterlagen
          </h2>
          <Files
            v-model="irpGlobalFiles[0].files"
            :item="item"
            :isIrpDashboard=true
          />
        </section>
        <hr class=""/>
        <section class="">
          <h2 class="font-bold">
            Spitalspezifische Unterlagen
          </h2>
          <Files
            v-model="joinAllHospitalsFiles"
            :item="item"
            :isIrpDashboard=true
          />
        </section>
      </div>
      <div class="flex flex-row gap-2">
        <section class="">
          <h2 class="font-bold">
            Reviewspezifische Unterlagen
          </h2>
          <Files
            v-model="joinAllReviewsFiles"
            :item="item"
            :isIrpDashboard=true
          />
        </section>
        <section class="">
          <h2 class="font-bold">
            Ihre Teilnahmebestätigung
          </h2>
          <Files
            v-model="irpUserFiles[0].files"
            :item="item"
            :isIrpDashboard=true
          />
        </section>
      </div>
    </div> -->
    <div class='flex flex-row mb-3 gap-2'>
      <button @click="showFiles(0)" v-bind:class = "(btnNumber === 0) ? 'bg-[#A34343] hover:bg-[#A34343]':''"> {{ $t('irp.general-IRP-documents') }} </button>
      <button @click="showFiles(1)" v-bind:class = "(btnNumber === 1) ? 'bg-[#A34343] hover:bg-[#A34343]':''"> {{ $t('irp.documents-addressed-to-your-institution') }}</button>
      <button @click="showFiles(2)" v-bind:class = "(btnNumber === 2) ? 'bg-[#A34343] hover:bg-[#A34343]':''"> {{ $t('irp.documents-related-to-your-reviews') }}</button>
      <button @click="showFiles(3)" v-bind:class = "(btnNumber === 3) ? 'bg-[#A34343] hover:bg-[#A34343]':''"> {{ $t('irp.your-participation-confirmations') }} </button>
    </div>
    <div class="flex flex-col self-center gap-4">
      <div class="flex flex-col gap-2">
        <section v-if="btnNumber === 0" class="border-2 rounded-md border-black p-2">
          <h2 class="font-bold">
            {{ $t('irp.general-IRP-documents') }}
          </h2>
          <Files
            v-model="joinAllGlobalFiles"
            :item="item"
            :isIrpDashboard=true
          />
        </section>
        <hr class=""/>
        <section v-if="btnNumber === 1" class="border-2 rounded-md border-black p-2">
          <h2 class="font-bold">
            {{ $t('irp.documents-addressed-to-your-institution') }}
          </h2>
          <Files
            v-model="joinAllHospitalsFiles"
            :item="item"
            :isIrpDashboard=true
          />
        </section>
      </div>
      <div class="flex flex-col gap-2">
        <section v-if="btnNumber === 2" class="border-2 rounded-md border-black p-2 mr-1">
          <h2 class="font-bold">
            {{ $t('irp.documents-related-to-your-reviews') }}
          </h2>
          <Files
            v-model="joinAllReviewsFiles"
            :item="item"
            :isIrpDashboard=true
          />
        </section>
        <section v-if="btnNumber === 3" class="border-2 rounded-md border-black p-2 ml-1">
          <h2 class="font-bold">
            {{ $t('irp.your-participation-confirmations') }}
          </h2>
          <Files
            v-model="joinAllUsersFiles"
            :item="item"
            :isIrpDashboard=true
          />
        </section>
      </div>
    </div>
    <div v-if="reviewsOpen.length" class="border rounded p-3 mb-4">
        <label class="text-lg text-bold mb-2">{{ $t('irp.open-requests') }}</label>
            <div v-for="review in reviewsOpen" :key="review.id">
              <div class="flex items-center border-b p-1 py-2 text-xs font-bold">
                <div class="w-36"></div>
                <div class="w-20 mx-2 ">{{ $t('irp.when') }}?</div>
                <div class="w-1/3 mx-2">{{ $t('irp.review-topic') }}</div>
                <div class="w-1/3">{{ $t('hospital') }}</div>
                <div class="w-1/3">{{ $t('irp.meetingpoint') }}</div>
              </div>
            <div class="flex items-center border-b p-1 py-2">
                <div @click="onUpdateReview(review.id, 2)" class="mr-2 p-1 border border-neutral-500 rounded w-16 text-center cursor-pointer hover:ring-2 hover:ring-green-300 hover:bg-green-100">
                    {{ $t('irp.yes') }}
                </div>
                <div  @click="onUpdateReview(review.id, 3)" class="p-1 border border-neutral-500 rounded w-16 text-center cursor-pointer hover:ring-2 hover:ring-red-300 hover:bg-red-100">
                    {{ $t('irp.no') }}
                </div>
                <div class="w-20 mx-2">
                    {{formatDate(review.date)}}
                </div>
                <div class="w-1/3 mx-2">
                    {{review.name}}
                </div>
                <div class="w-1/3">
                    {{review.hospital.name}}
                </div>
                <div class="w-1/3 mx-2">
                    {{review.meeting_point}}
                </div>
            </div>
        </div>
    </div>
    <div v-if="reviewsNotYetConfirmed.length" class="border rounded p-3 ">
        <label class="text-lg text-bold mb-2">{{ $t('irp.not-yet-confirmed-reviews') }}</label>
            <div v-for="review in reviewsNotYetConfirmed" :key="review.id">
              <div class="flex items-center border-b p-1 py-2 text-xs font-bold">
                <div class="w-52 mr-2 p-1"> </div>
                <div class="w-20 mx-2 ">{{ $t('irp.when') }}?</div>
                <div class="w-1/3 mx-2">{{ $t('irp.review-topic') }}</div>
                <div class="w-1/3">{{ $t('hospital') }}</div>
                <div class="w-1/3">{{ $t('irp.meetingpoint') }}</div>
              </div>
            <div class="flex items-center border-b p-1 py-2">
                <div class="w-52 mr-2 p-1 border border-orange-500 rounded text-center select-none bg-orange-700 text-white">
                    {{ $t('irp.not-yet-confirmed') }}
                </div>
                <div class="w-20 mx-2">
                    {{formatDate(review.date)}}
                </div>
                <div class="w-1/3 mx-2">
                    {{review.name}}
                </div>
                <div class="w-1/3">
                    {{review.hospital.name}}
                </div>
                <div class="w-1/3">
                    {{review.meeting_point}}
                </div>

            </div>
        </div>
    </div>
    <div v-if="reviewsConfirmed.length" class="border rounded p-3 ">
        <label class="text-lg text-bold mb-2">{{ $t('irp.confirmed-reviews') }}</label>
            <div v-for="review in reviewsConfirmed" :key="review.id">
              <div class="flex items-center border-b p-1 py-2 text-xs font-bold">
                <div class="w-36 mr-2 p-1"> </div>
                <div class="w-20 mx-2 ">{{ $t('irp.when') }}?</div>
                <div class="w-1/3 mx-2">{{ $t('irp.review-topic') }}</div>
                <div class="w-1/3">{{ $t('hospital') }}</div>
                <div class="w-1/3">{{ $t('irp.meetingpoint') }}</div>
              </div>
            <div class="flex items-center border-b p-1 py-2">
                <div class="mr-2 p-1 border border-neutral-500 rounded w-36 text-center select-none bg-blue-500 text-white">
                    {{ $t('irp.confirmed') }}
                </div>
                <div class="w-20 mx-2">
                    {{formatDate(review.date)}}
                </div>
               <div class="w-1/3 mx-2">
                    {{review.name}}
                </div>
                <div class="w-1/3">
                    {{review.hospital.name}}
                </div>
                <div class="w-1/3">
                    {{review.meeting_point}}
                </div>

            </div>
        </div>
    </div>
    <div v-if="reviewsCancelled.length" class="border rounded p-3 ">
        <label class="text-lg text-bold mb-2">{{ $t('irp.cancelled') }}</label>
            <div v-for="review in reviewsCancelled" :key="review.id">
              <div class="flex items-center border-b p-1 py-2 text-xs font-bold">
                <div class="w-36 mr-2 p-1"> </div>
                <div class="w-20 mx-2 ">{{ $t('irp.when') }}?</div>
                <div class="w-1/3 mx-2">{{ $t('irp.review-topic') }}</div>
                <div class="w-1/3">{{ $t('hospital') }}</div>
                <div class="w-1/3">{{ $t('irp.meetingpoint') }}</div>
              </div>
            <div class="flex items-center border-b p-1 py-2">
                <badge :state="5"></badge>
                <div class="w-20 mx-2">
                    {{formatDate(review.date)}}
                </div>
               <div class="w-1/3 mx-2">
                    {{review.name}}
                </div>
                <div class="w-1/3">
                    {{review.hospital.name}}
                </div>
                <div class="w-1/3">
                    {{review.meeting_point}}
                </div>

            </div>
        </div>
    </div>
    <div class="mt-10" v-if="user.is_staff">
      <h1 class="font-bold">{{ $t('irp.overview-reviews') }}</h1>
      <div v-for="(review, index) in reviews" :key="index + '_' + review">
        <h2 class="font-bold">{{formatDate(review.date)}} - {{review.name}}</h2>
        <div class="border-b p-1 py-2">
        <div v-for="reviewer in review.reviewers" :key="reviewer.id" class="flex items-center border-b p-1 py-2">
            <badge :state="reviewer.state"></badge>
            <div class="w-1/3 mx-2">
                {{reviewer.reviewer.user_email}}
            </div>
            <div class="w-1/3">
                {{reviewer.reviewer.user_first_name}}
            </div>
            <div class="w-1/3">
                {{reviewer.reviewer.user_last_name}}
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import dateMixin from '@/mixins/date'
import Badge from '@/components/irp/Badge.vue'
import Files from '../../components/display/Files.vue'

export default {
  components: { Badge, Files },
  mixins: [dateMixin],
  data () {
    return {
      // put here an item
      item: {
        id: 0
      },
      btnNumber: 0,
      allFilesFromHospitals: [],
      allFilesFromReview: [],
      allFilesFromGlobal: [],
      allFilesFromUser: []
    }
  },
  computed: {
    ...mapGetters({
      reviews: 'irp/reviews/all',
      hospitals: 'irp/hospitals/all',
      irpGlobalFiles: 'irp/irpGlobalFiles/all',
      irpUserFiles: 'irp/irpUserFiles/all',
      reviewById: 'irp/reviews/getById',
      reviewers: 'irp/reviewers/all',
      user: 'user'
    }),
    reviewsOpen () {
      var tmp = this.reviews.filter(r => {
        const reviewer = r.reviewers.find(f => f.reviewer.user === this.user.id)
        return reviewer && reviewer.state === 1
      })
      return tmp
    },
    reviewsConfirmed () {
      var tmp = this.reviews.filter(r => {
        const reviewer = r.reviewers.find(f => f.reviewer.user === this.user.id)
        return reviewer && reviewer.state === 4
      })
      return tmp
    },
    reviewsNotYetConfirmed () {
      var tmp = this.reviews.filter(r => {
        const reviewer = r.reviewers.find(f => f.reviewer.user === this.user.id)
        return reviewer && reviewer.state === 2
      })
      return tmp
    },
    reviewsCancelled () {
      var tmp = this.reviews.filter(r => {
        const reviewer = r.reviewers.find(f => f.reviewer.user === this.user.id)
        return reviewer && reviewer.state === 5
      })
      return tmp
    },
    hasPendingReviews () {
      return this.reviewsOpen.length + this.reviewsConfirmed.length + this.reviewsNotYetConfirmed.length > 0
    },
    joinAllHospitalsFiles () {
      this.hospitals.forEach((hospital) => {
        hospital.files.forEach((file) => {
          this.allFilesFromHospitals.push(file)
        })
      })

      return this.allFilesFromHospitals
    },
    joinAllReviewsFiles () {
      this.reviews.forEach((review) => {
        review.files.forEach((file) => {
          this.allFilesFromReview.push(file)
        })
      })
      return this.allFilesFromReview
    },
    joinAllUsersFiles () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.allFilesFromUser = []
      if (this.irpUserFiles.length) {
        this.irpUserFiles.forEach((userFile) => {
          this.allFilesFromUser.push(...userFile.files)
        })
      }

      return this.allFilesFromUser
    },
    joinAllGlobalFiles () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.allFilesFromGlobal = []
      if (this.irpGlobalFiles.length) {
        this.irpGlobalFiles.forEach((globalFile) => {
          this.allFilesFromGlobal.push(...globalFile.files)
        })
      }
      return this.allFilesFromGlobal
    }
  },
  methods: {
    ...mapActions({
      updateReview: 'irp/reviews/updateReview'
    }),
    onUpdateReview (id, state) {
      this.updateReview({ id: id, state: state })
    },
    getStateForReview (reviewId, reviewerId) {
      const review = this.reviewById(reviewId)
      const reviewer = review.reviewers.find(r => r.reviewer.id === reviewerId)
      return reviewer.state || -1
    },
    showFiles (indexBtn) {
      this.btnNumber = indexBtn
    }
  },
  async mounted () {
    await this.$store.dispatch('irp/reviews/load', { all: true })
    await this.$store.dispatch('irp/reviewers/load', { all: true })
    await this.$store.dispatch('irp/irpGlobalFiles/load', { all: true })
    await this.$store.dispatch('irp/irpUserFiles/load', { all: true })
    await this.$store.dispatch('irp/hospitals/load', { all: true })

    if (this.user.is_staff) {
      // await this.$store.dispatch('irp/reviewers/load', { all: true })
    }
  }

}
</script>
