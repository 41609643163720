import Crud from '../../crud'

var crud = new Crud('mailer/mails')

export default {
  namespaced: true,
  state: () => ({
    ...crud.state
  }),
  getters: {
    ...crud.getters,
    disableAdd: () => true,
    fields (state, getters, rootState, rootGetters) {
      return [
        { text: 'Erstellt am', value: 'created', format: 'DateTime', width: 150, readOnly: true },
        { text: 'Gesendet am', value: 'sent_at', format: 'DateTime', width: 150, readOnly: true },
        { text: 'Empfänger Email', value: 'email_to' },
        { text: 'Betreff', value: 'subject', hideList: true, readOnly: true },
        { text: 'Text', value: 'body', type: 'textarea', hideList: true, readOnly: true }
      ]
    }
  },
  mutations: {
    ...crud.mutations
  },
  actions: {
    ...crud.actions
  }
}
