<style type="text/css">
  .apexcharts-bar-area {
    margin-top: 30px;
  }
</style>
<template>
<div class="border-2 p-2 h-full w-full bg-white dark:bg-chart-dark dark:border-neutral-600" v-view.once="onceHandler">
  <VueApexCharts ref="chart" v-if="(visible || print) && !hideGraphic" width="100%" height="100%" :options="chartOptions" :series="series"></VueApexCharts>
  <chart-menu v-if="readOnly === false"
    :chart="chart"/>
  <div v-if="!visible" class="bg-neutral-200 dark:bg-neutral-800 animate-pulse h-full w-full"></div>
  <div v-if="this.data.length === 0 || hideGraphic" class="absolute top-1/2 left-1/2 -translate-x-1/2 text-lg font-bold">
    {{ $t('no-data') }}
  </div>
  <div v-if="showCategoryLimitModal" class="mpointer-events-none fixed w-full h-full top-0 left-0 flex items-start z-50">
    <div class="absolute w-full h-full bg-neutral-900 opacity-50"></div>
    <div class="bg-white dark:bg-neutral-900 w-[40%] mx-auto rounded shadow-lg z-50 overflow-y-auto mt-32">
      <div class="modal-content py-4 text-left px-6">
        <h2 class="font-semibold">
          Das Limit der darstellbaren Kategorien für diesen Grafiktyp wurde überschritten.
        </h2>
        <p class="text-sm">
          Bitte wählen Sie einen anderen Grafiktyp oder passen Sie die für die Grafik benutzten Attribute an.
        </p>
        <button class="my-2" @click="showCategoryLimitModal = false">Ok</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { round, uniqBy } from 'lodash'
import utilsMixin from '@/mixins/utils'
import ChartMenu from '@/components/charts/ChartMenu.vue'
import { mapGetters } from 'vuex'

function calculateMean (array) {
  const sum = array.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  return sum / array.length
}

function calculateMedian (array) {
  const sortedArray = array.slice().sort((a, b) => a - b)
  const middle = Math.floor(sortedArray.length / 2)

  if (sortedArray.length % 2 === 0) {
    return (sortedArray[middle - 1] + sortedArray[middle]) / 2
  } else {
    return sortedArray[middle]
  }
}

export default {
  mixins: [utilsMixin],
  name: 'Chart',
  components: {
    VueApexCharts,
    ChartMenu
  },
  props: {
    chart: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      default: 'de'
    },
    annotations: {
      type: Object,
      required: false,
      default: () => {}
    },
    isEditedChart: {
      type: Boolean,
      default: false
    },
    print: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    stacked: {
      type: Boolean,
      default: false
    },
    stackedFull: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      width: 0,
      totals: {},
      visible: false,
      seriesData: [],
      isDarkMode: false,
      stackType: undefined,
      showCategoryLimitModal: false,
      hideGraphic: false,
      MAX_CATEGORIES: 15
    }
  },
  computed: {
    ...mapGetters({
      filteredDataForChartId: 'results/evaluationStore/filteredDataForChartId'
    }),
    data () {
      return this.filteredDataForChartId(this.chart.i, this.chart.filters)
    },
    isSpecialMode () {
      return ['Count', 'Sum', 'Mean', 'Median', 'Unique'].includes(this.chart.addition)
    },
    chartType () {
      return this.chart.type
    },
    series () {
      return this.seriesData
    },
    groups () {
      return this.chart.groups
    },
    title () {
      if (!this.isEditedChart) {
        return this.translate(this.chart, 'title', this.language)
      } else {
        return this.translate(this.chart, 'title', this.chart.languageTabTitle ? this.chart.languageTabTitle : this.language)
      }
    },
    subtitle () {
      if (!this.isEditedChart) {
        return this.translate(this.chart, 'subtitle', this.language)
      } else {
        return this.translate(this.chart, 'subtitle', this.chart.languageTabSubtitle ? this.chart.languageTabSubtitle : this.language)
      }
    },
    isSplit () {
      return this.chart.splits.length > 0
    },
    viewSettings () {
      return this.chart.viewSettings
    },
    selectedSeries () {
      return this.chart.data
    },
    splitSeries () {
      return this.isSplit ? this.chart.splits[0].data : []
    },
    horizontal () {
      return this.chart.viewSettings?.indexOf('horizontal') > -1
    },
    showDatalabels () {
      return this.chart.viewSettings?.indexOf('datalabels') > -1
    },
    colorMinus10 () {
      return this.chart.viewSettings?.indexOf('color_less_10') > -1
    },
    getTitle () {
      return this.splitTitle(this.title, this.getWidth, { fontSize: '20px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
    },
    getSubTitle () {
      return this.splitTitle(this.subtitle, this.getWidth, { fontSize: '14px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
    },
    colorSheme () {
      return this.isDarkMode ? 'dark' : 'light'
    },
    chartOptions () {
      const suffix = !this.isSpecialMode ? '%' : ''
      var options = {
        title: {
          text: this.getTitle,
          align: 'center',
          offsetY: this.readOnly ? 0 : 35,
          margin: 35,
          style: {
            fontSize: '20px',
            fontWeight: 'normal',
            fontFamily: 'Nunito, sans-serif',
            color: this.colorSheme === 'dark' ? '#3a92cf' : '#333333'
          }
        },
        subtitle: {
          text: this.getSubTitle,
          align: 'center',
          margin: this.getSubTitle ? 10 : 0,
          offsetX: 0,
          offsetY: this.getTitle.length * 25 + (this.readOnly ? 25 : 55),
          style: {
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: 'Nunito, sans-serif',
            color: this.colorSheme === 'dark' ? '#3a92cf' : '#777777'
          }
        },
        theme: {
          mode: this.colorSheme
        },
        fill: {
          colors: [
            ({ value, seriesIndex, dataPointIndex }) => {
              if (this.colorMinus10 && this.totals[seriesIndex][dataPointIndex] < 10) {
                return '#a6a6a6'
              } else {
                return this.colorPalette[seriesIndex]
              }
            }
          ]
        },
        legend: {
          markers: {
            fillColors: this.colorPalette
          }
        },
        chart: {
          id: 'basic-bar',
          type: 'bar',
          stacked: this.getStacked || this.stackedFull,
          stackType: this.stackedFull ? '100%' : undefined,
          fontFamily: 'Nunito, sans-serif',
          toolbar: {
            show: false,
            tools: {},
            offsetX: -35
          },
          events: {
            mounted: (chart) => {
              if (this.$refs.chart === undefined) return
              this.width = this.$refs.chart.$el.clientWidth
              chart.windowResizeHandler()
            },
            updated: (chartContext, config) => {
              if (this.$refs.chart === undefined) return
              this.width = this.$refs.chart.$el.clientWidth
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: this.horizontal,
            columnWidth: '60%',
            dataLabels: {
              position: this.getStacked || this.stackedFull ? 'center' : 'top'
            }
          }
        },
        xaxis: {
          categories: this.categories,
          labels: {
            trim: false,
            maxHeight: 200
          },
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          }
        },
        yaxis: {
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          labels: {
            trim: true
          }
        },
        tooltip: {
          y: {
            formatter: (val, { dataPointIndex, seriesIndex }) => {
              const getKeys = Object.keys(this.totals) || []
              const modifier = Math.min(getKeys.length - 1, seriesIndex)
              const decimals = ['Mean', 'Median'].includes(this.chart.addition)
              const absolute = ['Count'].includes(this.chart.addition)
              const getIndex = getKeys[modifier]
              const compute = Object.values(this.totals[getIndex])
              const computation = compute.reduce((total, currentValue) => total + currentValue, 0)
              if (!this.isSpecialMode) {
                return `${val.toFixed(1)}% (${this.$t('count')}: ${this.totals[getIndex][dataPointIndex]})`
              } else {
                if (absolute) {
                  return `${val} (${this.$t('Anteil')}: ${(val / computation * 100).toFixed(1)}%)`
                } else if (decimals) {
                  return val.toFixed(2)
                } else {
                  return val
                }
              }
            }
          }
        },
        dataLabels: {
          enabled: this.showDatalabels,
          formatter: (val, { d }) => {
            return isNaN(val) ? '' : val.toFixed(0) + suffix
          },
          style: {
            fontSize: '12px',
            colors: [this.colorSheme === 'dark' ? '#ffffff' : '#304758']
          }
        },
        annotations: this.annotations
      }
      if (!this.horizontal) {
        options.yaxis.labels = {
          show: true,
          formatter: function (val) {
            return val + suffix
          }
        }
        options.xaxis.labels = {
          show: true,
          formatter: function (val) {
            return val
          }
        }
        options.grid = {
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: this.chartType === 'bar'
            }
          }
        }
        options.dataLabels.offsetX = 0
        options.dataLabels.offsetY = -20
      } else {
        options.grid = {
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: false
            }
          }
        }
        options.yaxis.labels = {
          show: true,
          formatter: function (val) {
            return val
          }
        }
        options.xaxis.labels = {
          show: true,
          formatter: function (val) {
            return val + suffix
          }
        }
        options.dataLabels.offsetX = 35
        options.dataLabels.offsetY = 0
      }
      return options
    },
    getWidth () {
      return this.width
    },
    colorPalette () {
      return ['#00E396', '#008FFB', '#FEB019', '#FF4560', '#775DD0', '#3F51B5', '#4CAF50', '#F9CE1D', '#FF9800', '#33B2DF', '#546E7A', '#D4526E', '#13D8AA', '#A5978B']
    },
    categories () {
      var tmp = []
      var split = 20

      if (this.type && this.type === 'multi_number') {
        split = 60
        tmp = this.selectedSeries.map(serie => this.translate(serie, 'label', this.language))
        tmp = tmp.flat(2).map(t => [t])
      } else {
        var series = this.selectedSeries.filter(s => s.data)
        this.selectedSeries.filter(s => s.data === undefined).forEach(s => {
          series = [...series, ...s.keys]
        })
        tmp = series.reduce((acc, serie) => {
          serie.data.forEach((v, index) => {
            if (!acc[index]) {
              acc[index] = []
            }
            if (acc[index].indexOf(this.translate(v, 'label', this.language) || v.key) === -1) {
              acc[index].push(this.translate(v, 'label', this.language) != null ? this.translate(v, 'label', this.language) : v.key)
            }
          })
          return acc
        }, [])
      }
      var result = []
      tmp.forEach(str => {
        result.push(this.splitter(str[0], split))
      })
      if (this.isSpecialMode) {
        const addition = this.chart.addition
        if (addition !== 'Count') {
          result = [['']]
        }
      }
      return result
    },
    getStacked () {
      return this.stacked
    },
    getStackType () {
      return this.stackType
    }
  },
  methods: {
    specialMode (items) {
      for (const i of items) {
        const initial = i.total[items.indexOf(i)]
        const addition = this.chart.addition
        const absolute = Object.values(initial)

        if (addition === 'Count') {
          i.data = absolute
        }

        if (i.sum.length > 0) {
          if (addition === 'Sum') {
            i.data = [i.sum.reduce((total, currentValue) => total + currentValue, 0)]
          }
        }

        if (i.mean.length > 0) {
          if (addition === 'Mean') {
            i.data = [calculateMean(i.mean)]
          }
          if (addition === 'Median') {
            i.data = [calculateMedian(i.mean)]
          }
          if (addition === 'Unique') {
            const uniqueArray = [...new Set(i.mean)]
            i.data = [uniqueArray.length]
          }
        }
      }
    },

    setupData () {
      this.totals = {}

      if (!this.visible || this.data.length <= 0) {
        this.seriesData = []
        return
      }
      var result = []
      // Groupieren nach Attribut
      if (this.chart.groups.length > 0) {
        var results = []
        this.chart.groups.forEach(group => {
          group.data.forEach((v, groupIx) => {
            result = []

            var filtered = []
            if (v.customFunc) {
              filtered = this.data.filter(d => v.customFunc(d.attributes[group.key]))
            } else {
              filtered = this.data.filter(d => v.value && d.attributes[group.key] && d.attributes[group.key].toString() === v.value.toString())
            }
            if (filtered.length > 0) {
              if (this.isSplit) {
                result = this.buildSplitAndGroupedSeries(result, filtered, groupIx)
              } else {
                result = this.buildSeries(result, filtered, groupIx)
              }

              result.forEach((r, rIx) => {
                results.push({
                  name: this.translate(v, 'label', this.language) + ' ' + r.name,
                  data: r.data,
                  sum: r.sum,
                  mean: r.mean,
                  total: r.total
                })
              })
            }
          })
        })
        this.hideGraphic = false
        this.checkLimitOfCategoriesInGraphic(results.length)
        this.seriesData = results
        if (this.isSpecialMode) {
          this.specialMode(results)
        }

        return
      }

      if (this.type && this.type === 'multi_number') {
        result = this.buildSeriesMultiNumber(result, this.data)
      } else {
        if (this.isSplit) {
          result = this.buildSplitSeries(result, this.data)
        } else {
          result = this.buildSeries(result, this.data)
        }
      }
      this.hideGraphic = false
      this.seriesData = result

      if (this.isSpecialMode) {
        this.specialMode(result)
      }
    },
    onceHandler (e) {
      this.visible = true
    },
    checkLimitOfCategoriesInGraphic (currentCountOfCategories) {
      if (currentCountOfCategories > this.MAX_CATEGORIES) {
        this.hideGraphic = true
        this.showCategoryLimitModal = true
      } else {
        this.showCategoryLimitModal = false
      }
    },
    buildSeriesMultiNumber (result) {
      this.selectedSeries.forEach((serie, serieIx) => {
        this.totals[serieIx] = {}
        serie.options.forEach((option, ix) => {
          var obj = {}
          obj.name = option.label
          obj.data = []
          serie.data.forEach((v) => {
            // remove undefined
            const tmp = this.data.filter(value => (value.attributes[v.key][option.name_new] !== undefined)).map(value => parseFloat(value.attributes[v.key][option.name_new]))
            const value = tmp.reduce((prev, curr) => {
              return prev + curr
            }, 0) / tmp.length
            this.totals[serieIx][ix] = tmp.length
            obj.data.push(round(value, 2))
          })
          result.push(obj)
        })
      })
      return result
    },
    calculateResults (result, serie, data) {
      serie.data.forEach((v, ix) => {
        result[ix] = result[ix] || 0
        if (v.customFunc) {
          result[ix] += data.filter(value => v.measure.id === value.measure && v.customFunc(value.attributes[v.key])).length
        } else {
          result[ix] += data.filter(value => v.measure.id === value.measure && value.attributes[v.key] != null && String(value.attributes[v.key]) === String(v.value)).length
        }
      })
      return result
    },
    calculateSplitResults (result, serie, splitValue, data) {
      serie.data.forEach((v, ix) => {
        result[ix] = result[ix] || 0
        var value = 0
        value = data.filter(value => {
          var result = false
          if (v.customFunc) {
            result = v.customFunc(v.measure.id === value.measure && value.attributes[v.key])
          } else {
            result = String(v.measure.id === value.measure && value.attributes[v.key]) === String(v.value)
          }

          if (splitValue.customFunc) {
            result = splitValue.customFunc(value.attributes[splitValue.key]) && result
          } else {
            result = value.attributes[splitValue.key] != null && String(value.attributes[splitValue.key]) === String(splitValue.value) && result
          }

          return result
        }).length
        result[ix] = value
      })
      return result
    },
    buildSeries (result, data, groupIx = 0) {
      const hasMultipleMeasures = uniqBy(this.selectedSeries, v => v.measure ? v.measure.id : 0).length > 1
      this.selectedSeries.forEach((serie, serieIx) => {
        var total = 0
        this.totals[serieIx + groupIx] = {}
        var obj = {}
        if (hasMultipleMeasures && serie.measure) {
          obj.name = `${serie.measure.name}: ${serie.label || serie.title}`
        } else {
          // function that choose serie.label_fr or serie.label or serie.label_it
          const getCurrentLabel = (optionData) => {
            let currentTitle = ''
            if (this.language === 'it' && (optionData.label_it || optionData.title_it)) {
              currentTitle = optionData.label_it ? optionData.label_it : optionData.title_it
            } else if (this.language === 'fr' && (optionData.label_fr || optionData.title_fr)) {
              currentTitle = optionData.label_fr ? optionData.label_fr : optionData.title_fr
            } else {
              currentTitle = optionData.label ? optionData.label : optionData.title
            }
            return currentTitle
          }
          obj.name = getCurrentLabel(serie)
        }

        obj.data = []
        obj.sum = []
        obj.mean = []
        var tmp = {}
        var legend = []
        if (serie.data) {
          tmp = this.calculateResults(tmp, serie, data)
          legend = serie.data.map(_ => _.value)
        } else {
          serie.keys.forEach(dim => {
            tmp = this.calculateResults(tmp, dim, data)
            legend = dim.data.map(_ => _.value)
          })
        }
        Object.keys(tmp).forEach((ix, index) => {
          if (typeof legend[index] === 'number') {
            obj.sum.push(Math.round(tmp[ix] * legend[index] * 100) / 100)
          }
          obj.mean.push(...new Array(tmp[ix]).fill(legend[index]))
          obj.data.push(tmp[ix])
          total = total + tmp[ix]
          this.totals[serieIx + groupIx][ix] = (parseInt(this.totals[serieIx + groupIx][ix]) || 0) + tmp[ix]
        })

        obj.data.forEach((v, index) => {
          obj.data[index] = total > 0 ? round(((v / total) * 100), 1) : 0
        })
        obj.total = this.totals
        result.push(obj)
      })
      return result
    },
    buildSplitSeries (result, data) {
      const hasMultipleMeasures = uniqBy(this.selectedSeries, v => v.measure ? v.measure.id : 0).length > 1
      this.splitSeries.forEach((splitValue, splitIx) => {
        this.totals[splitIx] = {}
        this.selectedSeries.forEach((serie, serieIx) => {
          var total = 0
          var obj = {}
          if (hasMultipleMeasures) {
            obj.name = `${splitValue.measure.name}: ${splitValue.label}`
          } else {
            obj.name = this.translate(splitValue, 'label', this.language)
          }
          obj.data = []
          obj.sum = []
          obj.mean = []
          var tmp = {}
          var legend = []
          if (serie.data) {
            tmp = this.calculateSplitResults(tmp, serie, splitValue, data)
            legend = serie.data.map(_ => _.value)
          } else {
            serie.keys.forEach(dim => {
              tmp = this.calculateSplitResults(tmp, dim, splitValue, data)
              legend = dim.data.map(_ => _.value)
            })
          }

          Object.keys(tmp).forEach((ix, index) => {
            if (typeof legend[index] === 'number') {
              obj.sum.push(Math.round(tmp[ix] * legend[index] * 100) / 100)
            }
            obj.mean.push(...new Array(tmp[ix]).fill(legend[index]))
            obj.data.push(tmp[ix])
            total = total + tmp[ix]
            this.totals[splitIx][ix] = (parseInt(this.totals[splitIx][ix]) || 0) + tmp[ix]
          })
          obj.data.forEach((v, index) => {
            obj.data[index] = total > 0 ? round(((v / total) * 100), 1) : 0
          })
          obj.total = this.totals
          result.push(obj)
        })
      })
      return result
    },
    buildSplitAndGroupedSeries (result, data, groupIx) {
      const hasMultipleMeasures = uniqBy(this.selectedSeries, v => v.measure ? v.measure.id : 0).length > 1
      this.splitSeries.forEach((splitValue, splitIx) => {
        const newIndex = Object.keys(this.totals).length
        this.totals[newIndex] = {}
        this.selectedSeries.forEach((serie, serieIx) => {
          var total = 0
          var obj = {}
          if (hasMultipleMeasures) {
            obj.name = `${splitValue.measure.name}: ${splitValue.label}`
          } else {
            obj.name = this.translate(splitValue, 'label', this.language)
          }
          obj.data = []
          obj.sum = []
          obj.mean = []
          var tmp = {}
          var legend = []
          if (serie.data) {
            tmp = this.calculateSplitResults(tmp, serie, splitValue, data)
            legend = serie.data.map(_ => _.value)
          } else {
            serie.keys.forEach(dim => {
              tmp = this.calculateSplitResults(tmp, dim, splitValue, data)
              legend = dim.data.map(_ => _.value)
            })
          }
          Object.keys(tmp).forEach((ix, index) => {
            if (typeof legend[index] === 'number') {
              obj.sum.push(Math.round(tmp[ix] * legend[index] * 100) / 100)
            }
            obj.mean.push(...new Array(tmp[ix]).fill(legend[index]))
            obj.data.push(tmp[ix])
            total = total + tmp[ix]
            this.totals[newIndex][ix] = (parseInt(this.totals[newIndex][ix]) || 0) + tmp[ix]
          })
          obj.data.forEach((v, index) => {
            obj.data[index] = total > 0 ? round(((v / total) * 100), 1) : 0
          })
          obj.total = this.totals
          result.push(obj)
        })
      })
      return result
    }
  },
  mounted () {
    this.setupData()
    this.isDarkMode = document.documentElement.classList.contains('dark')
  },
  watch: {
    selectedSeries: {
      handler (val) {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    splitSeries: {
      handler (val) {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    groups: {
      handler (val) {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    viewSettings: {
      handler (val, old) {
        this.setupData()
      }
    },
    data: {
      handler (val) {
        this.setupData()
      }
    },
    visible: {
      handler (val) {
        this.setupData()
      }
    }
  }
}
</script>
