export default {
  methods: {
    arrayToCsv ({ data = null, columnDelimiter = ',', lineDelimiter = '\n' }) {
      if (data === null || !data.length) {
        return null
      }

      const keys = Object.keys(data[0])

      var result = 'data:text/csv;charset=utf-8,'
      result += keys.join(columnDelimiter)
      result += lineDelimiter

      data.forEach(item => {
        var ctr = 0
        keys.forEach(key => {
          if (ctr > 0) {
            result += columnDelimiter
          }

          result += typeof item[key] === 'string' && item[key].includes(columnDelimiter) ? `"${item[key]}"` : `"${item[key]}"` || ''
          ctr++
        })
        result += lineDelimiter
      })

      return result
    }
  }
}
