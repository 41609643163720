
export default {

  async bind (el, binding, vnode) {
    let result = null
    const vm = vnode.context
    const category = binding.value
    const array = ['evaluations', 'measures']

    if (array.includes(category)) {
      setTimeout(() => {
        const store = vm.$store.state.results
        const storeToolTip = store[category].toolTip
        const textinElement = [].reduce.call(el.childNodes, function (a, b) {
          return a + (b.nodeType === 3 ? b.textContent : '')
        }, '')

        for (const i in storeToolTip) {
          if (i.endsWith('--')) {
            const subString = i.slice(0, -2).trim()
            if (textinElement.includes(subString)) {
              result = storeToolTip[i]
              break
            }
          } else {
            if (textinElement.trim() === i) {
              result = storeToolTip[i]
              break
            }
          }
        }

        if (result) {
          const tooltipSpan = document.createElement('span')
          const icontip = document.createElement('i')
          const text = document.createTextNode(result)
          tooltipSpan.appendChild(text)
          tooltipSpan.className = 'custom-tooltip'
          icontip.className = 'info-icon'
          icontip.textContent = '?'
          let showTooltipTimeout
          el.appendChild(icontip)
          el.appendChild(tooltipSpan)
          icontip.onmouseover = () => {
            showTooltipTimeout = setTimeout(() => {
              tooltipSpan.style.display = 'inline'
            }, 500)
          }
          icontip.onmouseout = () => {
            clearTimeout(showTooltipTimeout)
            tooltipSpan.style.display = 'none'
          }
        }
      }, 2000)
    }
  }
}
