
<template>
  <div class="w-1/2 mt-4 z-50 border-t-4 rounded-b px-4 py-3 shadow-md" :class="[notify.error ? 'bg-red-100 border-red-500 text-red-900' : 'bg-emerald-100 border-green-500 text-emerald-900']" role="alert">
    <div class="flex">
      <div class="py-1"><svg class="fill-current h-6 w-6 mr-4" :class="[notify.error ? 'text-red-500' : 'text-emerald-500']" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
      <div class="w-full">
        <p class="font-bold">{{notify.title}}</p>
        <p class="text-sm">{{notify.message}}</p>
      </div>
      <div @click="hideMe()" class="cursor-pointer">
      <svg class="fill-current w-4 h-4 justify-end" :class="[notify.error ? 'text-red-500' : 'text-emerald-500']" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09   9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    notify: Object
  },
  data: function () {
    return {
      notifyObject: JSON.parse(JSON.stringify(this.notify))
    }
  },
  methods: {
    hideMe () {
      this.$store.commit('removeNotification', this.notifyObject)
    }
  },
  created () {
    if (!this.notify.permanent) {
      setTimeout(() => {
        this.hideMe()
      }, 3000)
    }
  }
}
</script>
