<style type="text/css">
  .apexcharts-bar-area {
    margin-top: 30px;
  }
</style>
<template>
  <div class="border-2 p-2 h-full w-full bg-white dark:bg-chart-dark  dark:border-neutral-600" v-view.once="onceHandler">
    <VueApexCharts v-if="visible || print" ref="chart"  width="100%" height="100%" :options="chartOptions" :series="series"></VueApexCharts>
    <chart-menu v-if="readOnly === false" :chart="chart"></chart-menu>
    <div v-if="!visible" class="bg-neutral-200 animate-pulse h-full w-full"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import { clone, uniqBy, round } from 'lodash'
import utilsMixin from '@/mixins/utils'
import ChartMenu from '@/components/charts/ChartMenu.vue'

export default {
  mixins: [utilsMixin],
  name: 'IkaChart',
  components: {
    VueApexCharts,
    ChartMenu
  },
  props: {
    chart: {
      type: Object,
      required: true
    },
    annotations: {
      type: Object,
      required: false,
      default: () => {}
    },
    isEditedChart: {
      type: Boolean,
      default: false
    },
    language: {
      type: String,
      default: 'de'
    },
    print: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      totals: {},
      height: 0,
      width: null,
      visible: false,
      baseColors: ['#00E396', '#008FFB', '#FEB019', '#FF4560', '#775DD0', '#3F51B5', '#4CAF50'],
      seriesData: [],
      isDarkMode: false
    }
  },
  computed: {
    ...mapGetters({
      filteredDataForChartId: 'results/evaluationStore/filteredDataForChartId'
    }),
    data () {
      return this.filteredDataForChartId(this.chart.i, this.chart.filters)
    },
    series () {
      return this.seriesData
    },
    title () {
      if (!this.isEditedChart) {
        return this.translate(this.chart, 'title', this.language)
      } else {
        return this.translate(this.chart, 'title', this.chart.languageTabTitle ? this.chart.languageTabTitle : this.language)
      }
    },
    subtitle () {
      if (!this.isEditedChart) {
        return this.translate(this.chart, 'subtitle', this.language)
      } else {
        return this.translate(this.chart, 'subtitle', this.chart.languageTabSubtitle ? this.chart.languageTabSubtitle : this.language)
      }
    },
    isSplit () {
      return this.chart.splits.length > 0
    },
    selectedSeries () {
      return this.chart.data
    },
    splitSeries () {
      return this.chart.splits.length > 0 ? this.chart.splits[0].data : []
    },
    config () {
      return this.chart.config
    },
    answerKeys () {
      return this.chart.answerKeys
    },
    chartHeight () {
      return this.height
    },
    getTitle () {
      return this.splitTitle(this.title, this.getWidth, { fontSize: '20px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
    },
    getSubTitle () {
      return this.splitTitle(this.subtitle, this.getWidth, { fontSize: '14px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
    },
    colorSheme () {
      return this.isDarkMode ? 'dark' : 'light'
    },
    chartOptions () {
      return {
        title: {
          text: this.getTitle,
          align: 'center',
          offsetY: this.readOnly ? 0 : 35,
          margin: 35,
          style: {
            fontSize: '20px',
            fontWeight: 'normal',
            fontFamily: 'Nunito, sans-serif',
            color: this.colorSheme === 'dark' ? '#3a92cf' : '#333333'
          }
        },
        subtitle: {
          text: this.getSubTitle,
          align: 'center',
          margin: this.getSubTitle ? 5 : 0,
          offsetX: 0,
          offsetY: this.getTitle.length * 25 + (this.readOnly ? 25 : 55),
          style: {
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: 'Nunito, sans-serif',
            color: this.colorSheme === 'dark' ? '#3a92cf' : '#777777'
          }
        },
        theme: {
          mode: this.colorSheme
        },
        chart: {
          id: 'basic-bar',
          type: 'bar',
          fontFamily: 'Nunito, sans-serif',
          toolbar: {
            show: false
          },
          events: {
            mounted: (chart) => {
              if (this.$refs.chart === undefined) return
              this.height = this.$refs.chart.$el.clientHeight
              this.width = this.$refs.chart.$el.clientWidth
              chart.windowResizeHandler()
            },
            updated: (chartContext, config) => {
              if (this.$refs.chart === undefined) return
              this.height = this.$refs.chart.$el.clientHeight
              this.width = this.$refs.chart.$el.clientWidth
            }
          }
        },
        colors: this.colors,
        xaxis: {
          min: this.meanMin,
          max: this.meanMax,
          tickAmount: this.meanMax,
          labels: {
            minHeight: 40
          }
        },
        yaxis: {
          labels: {
            maxWidth: 'auto',
            style: {
              fontSize: '13px'
            }
          }
        },
        fill: {
          colors: [
            ({ value, seriesIndex, dataPointIndex }) => {
              if (value > 90) {
                return '#bfbfbf'
              }
              return this.colors[seriesIndex]
            }
          ]
        },
        legend: {
          show: true,
          horizontalAlign: 'right',
          showForSingleSeries: true,
          customLegendItems: this.customLegendItems,
          markers: {
            fillColors: this.colors
          }
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'end',
          offsetX: 0,
          formatter: (value, { seriesIndex, dataPointIndex, w }) => {
            if (value === 99) {
              if (this.language === 'de') {
                return 'nicht bekannt / nicht anwendbar'
              } else {
                return 'inconnu / non applicable'
              }
            }
            if (value === 100) {
              if (this.language === 'de') {
                return 'nicht beantwortet'
              } else {
                return 'pas répondu'
              }
            }
            if (value === 0.2) {
              return '0'
            }
            return value
          }
        },
        tooltip: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff']
        },
        grid: {
          borderColor: this.colorSheme === 'dark' ? '#555' : '#90A4AE',
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '60%',
            columnWidth: '60%',
            dataLabels: {
              position: 'top'
            }
          }
        },
        annotations: this.getAnnotations
      }
    },
    customLegendItems () {
      var tmp = [this.$t('mean', this.language)]
      if (this.chart.groups.length) {
        tmp = []
        this.chart.groups.forEach(group => {
          group.data.forEach(v => {
            tmp.push(this.translate(v, 'label', this.language))
          })
        })
      }

      return tmp
    },

    colors () {
      var tmp = [this.baseColors[0]]
      if (this.chart.groups.length) {
        tmp = []
        this.chart.groups.forEach((group) => {
          group.data.forEach((v, ix) => {
            tmp.push(this.baseColors[ix])
          })
        })
      }
      return tmp
    },
    getAnnotations () {
      var tmp = {
        texts: []
      }
      if ([...new Set(this.answers.map(a => a[0]))].join(' | ') !== '') {
        tmp.texts = [{
          x: 50,
          y: this.chartHeight - 20,
          text: `${this.meanMin}  = ${[...new Set(this.answers.map(a => a[0]))].join(' | ')}`,
          appendTo: '.apexcharts-annotations',
          fontWeight: 'bold'
        },
        {
          x: 50,
          y: this.chartHeight - 5,
          text: `${this.meanMax} = ${[...new Set(this.answers.map(a => a[this.meanMaxLabel]))].join(' | ')}`,
          appendTo: '.apexcharts-annotations',
          fontWeight: 'bold'
        }]
      }
      if (this.annotations.texts !== undefined) {
        tmp.texts = [...tmp.texts, ...this.annotations.texts]
      }
      return tmp
    },
    categories () {
      var result = []
      const hasMultipleMeasures = uniqBy(this.selectedSeries, v => v.measure.id).length > 1
      this.selectedSeries.forEach(v => {
        var label = this.translate(v, 'label', this.language) || v.key
        if (hasMultipleMeasures) {
          label = `${v.measure.name}: ${label}`
        }
        var tmp = this.splitter(label, 60)
        result.push(tmp)
      })
      return result
    },
    answers () {
      var objects = []
      Object.entries(this.config).forEach(([key, value]) => {
        var neg = clone(value.negativeValues)
        neg.reverse()

        const tmp = neg.concat(value.positiveValues)
        objects.push({ measure: parseInt(key), values: tmp })
      })

      var result = []

      this.selectedSeries.filter(s => s.data !== undefined).forEach((serie, index) => {
        var labels = []
        objects.forEach(obj => {
          obj.values.forEach(value => {
            const entry = serie.data.find(d => d.measure.id === obj.measure && String(d.value) === String(value))
            if (entry !== undefined) {
              labels.push(this.translate(entry, 'label', this.language) || entry.key)
            }
          })
        })
        result.push(labels)
      })
      return result
    },
    meanMax () {
      const config = this.config[this.chart.measures[0]]

      if (config.meanMax !== undefined) {
        return parseInt(config.meanMax)
      }

      return config.negativeValues.length + config.positiveValues.length
    },
    meanMaxLabel () {
      const config = Object.values(this.config)[0]

      if (config.meanMax !== undefined) {
        return parseInt(config.meanMax)
      }
      return config.negativeValues.length + config.positiveValues.length - 1
    },
    meanMin () {
      const config = Object.values(this.config)[0]
      if (config.meanMin !== undefined) {
        return parseInt(config.meanMin)
      }
      return 1
    },

    getWidth () {
      return this.width
    }
  },
  methods: {
    setupData () {
      if (!this.visible || this.data.length <= 0) {
        return
      }
      var result = []
      // Groupieren nach Attribut
      if (this.chart.groups.length > 0) {
        var results = []
        this.chart.groups.forEach(group => {
          group.data.forEach(v => {
            result = []

            const filtered = this.data.filter(d => d && d.attributes[group.key].toString() === v.value.toString())
            if (filtered.length > 0) {
              if (this.isSplit) {
                result = this.buildSplitSeries(result, filtered)
              } else {
                result = this.buildSeries(result, filtered)
              }
              results.push({
                name: filtered.length > 1 ? this.$t('mean', this.language) + ': ' + this.translate(v, 'label', this.language) : this.translate(v, 'label', this.language),
                data: result
              })
            }
          })
        })
        this.seriesData = results
        return
      }
      if (this.isSplit) {
        result = this.buildSplitSeries(result, this.data)
      } else {
        result = this.buildSeries(result, this.data)
      }
      this.seriesData = [{
        name: this.$t('mean', this.language),
        data: result
      }]
    },
    onceHandler (e) {
      this.visible = true
    },
    buildSplitSeries (result, rawData) {
      this.splitSeries.forEach((splitValue, splitIx) => {
        this.totals[splitIx] = 0
        this.selectedSeries.forEach((v, serieIx) => {
          var total = 0
          var data = 0
          var values = []
          rawData.forEach(value => {
            if (String(value.attributes[splitValue.key]) === String(splitValue.value)) {
              const measure = value.measure

              if (this.answerKeys[measure]) {
                this.answerKeys[measure].forEach((answerKey, index) => {
                  if (value.attributes[answerKey] !== null) {
                    const isInNeg = this.config[measure].negativeValues.includes(String(value.attributes[answerKey]))
                    const isInPos = this.config[measure].positiveValues.includes(String(value.attributes[answerKey]))
                    if ((isInNeg || isInPos) && answerKey === v.key) {
                      total += 1
                      var ix = -1
                      if (!isNaN(parseInt(value.attributes[answerKey]))) {
                        ix = parseInt(value.attributes[answerKey])
                        data += ix
                        values.push(ix)
                      } else {
                        if (isInNeg) {
                          ix = this.config[measure].negativeValues.length - 1 - this.config[measure].negativeValues.indexOf(String(value.attributes[answerKey]))
                        } else {
                          ix = this.config[measure].negativeValues.length + this.config[measure].positiveValues.indexOf(String(value.attributes[answerKey]))
                        }
                        data += (ix + 1)
                        values.push(ix + 1)
                      }
                      this.totals[splitIx] = this.totals[splitIx] + 1
                    }
                  }
                })
              }
            }
          })
          result.push({
            x: this.translate(splitValue, 'label', this.language),
            y: round(data / total, 2),
            values: values,
            total: total,
            goals: []
          })
        })
      })

      return result
    },
    buildSeries (result, rawData) {
      this.selectedSeries.forEach((v, serieIx) => {
        var total = 0
        var data = 0
        var values = []
        this.totals[serieIx] = 0
        rawData.forEach(value => {
          const measure = value.measure
          if (v.answerKeys[measure]) {
            v.answerKeys[measure].forEach((answerKey, index) => {
              if (value.attributes[answerKey] !== null) {
                const isInNeg = this.config[measure].negativeValues.includes(String(value.attributes[answerKey]))
                const isInPos = this.config[measure].positiveValues.includes(String(value.attributes[answerKey]))
                if (answerKey === v.key) {
                  total += 1
                  var ix = -1
                  if (!isNaN(parseInt(value.attributes[answerKey]))) {
                    ix = parseInt(value.attributes[answerKey])
                    if (ix === -1) {
                      values.push(99)
                      data += 99
                    } else if (ix === 0) {
                      values.push(0.2)
                      data += 0.2
                    } else {
                      data += ix
                      values.push(ix)
                    }
                    this.totals[serieIx] = (parseInt(this.totals[serieIx]) || 0) + 1
                  } else if (isInNeg || isInPos) {
                    if (isInNeg) {
                      ix = this.config[measure].negativeValues.indexOf(String(value.attributes[answerKey]))
                    } else {
                      ix = this.config[measure].negativeValues.length + this.config[measure].positiveValues.indexOf(String(value.attributes[answerKey]))
                    }
                    data += (ix + 1)
                    values.push(ix + 1)
                    this.totals[serieIx] = (parseInt(this.totals[serieIx]) || 0) + 1
                  } else {
                    values.push(100)
                    data += 100
                  }
                }
              }
            })
          }
        })

        const meanValue = total === 0 ? 0 : round(data / total, 2)
        result.push({
          x: this.categories[serieIx],
          y: meanValue,
          values: values,
          total: total
        })
      })

      return result
    },

    transpose (a) {
      return Object.keys(a[0]).map(c => {
        return a.map(r => { return r[c] })
      })
    },
    // swap negative values for display
    swapNegativeValues (a, middle) {
      var tmp = clone(a)
      tmp = tmp.slice(0, middle + 1).reverse().concat(a.slice(middle + 1))
      return tmp
    }
  },
  mounted () {
    this.setupData()
    this.isDarkMode = document.documentElement.classList.contains('dark')
  },
  created () {
    this.$root.$on('updateLanguageTabTitle', eventSetTitle => {
      this.languageTabTitle = eventSetTitle
    })

    this.$root.$on('updateLanguageTabSubtitle', eventSetSubtitle => {
      this.languageTabSubtitle = eventSetSubtitle
    })
  },
  watch: {
    data: {
      handler (val) {
        this.setupData()
      }
    },
    visible: {
      handler (val) {
        this.setupData()
      }
    },
    selectedSeries: {
      handler (val) {
        this.setupData()
      }
    },
    splitSeries: {
      handler (val) {
        this.setupData()
      }
    }
  }
}
</script>
