<template>
<div class="flex flex-col items-center justify-center h-screen bg-gradient-to-t from-red-900 to-red-500">
    <div class="flex flex-col items-center justify-center bg-neutral-100 p-3 rounded-lg mb-2 w-96 h-48 text-center">
      <div v-if="isResponsible">
      <p class="text-lg font-bold">{{$t('thank-you')}}</p>
      <a class="mt-2 block text-secondary font-bold hover:text-primary" href="/">w² Apps</a>
      </div>
      <div class="text-center" v-else>
      <p class="text-lg font-bold w-full">
        {{$t('responsible-text')}}
      </p>
      <a class="mt-2 block text-secondary font-bold hover:text-primary" :href="'mailto:'+email">{{email}}</a>
      </div>

    </div>
  <Footer></Footer>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Footer from '@/components/Footer.vue'
import ApiClient from '@/assets/js/ApiClient.js'
export default {
  components: {
    Footer
  },
  computed: {
    ...mapGetters({
      contentTypeByName: 'accounts/contenttypes/getByName'
    }),
    email () {
      return this.$route.query.email || 'info@w-hoch2.ch'
    },
    isResponsible () {
      return this.$route.query.isResponsible === '1'
    }
  },
  async mounted () {
    await this.$store.dispatch('accounts/contenttypes/load', { all: true })
    const isResponsible = this.$route.query.isResponsible
    const token = this.$route.query.token
    const contentTypeId = this.contentTypeByName(this.$route.query.typ).id
    ApiClient.post('responsible/', { token: token, isResponsible: isResponsible, contentType: contentTypeId })
  }
}
</script>
