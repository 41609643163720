<template>
    <div class="mt-6 mb-10">
        <label class="mt-2">{{ translate(dimensions_translation, 'dimensions', language) }}</label>
        <div v-for="(dim, index) in dimensions" :key="index" class="bg-neutral-200 dark:bg-neutral-800 p-2 mb-2">
            <div class="flex items-center cursor-pointer" @click="updateDimension(index, dim)">
                <div class="w-full"> {{ translate(dim, 'title', language) }} ({{dim.data.length}} {{ $t('attribute') }})</div>
                <div @click.stop="removeDimension(index)" class="cursor-pointer flex items-center px-2"><i class="material-icons">delete</i></div>
            </div>
        </div>
        <div @click="addDimension()" class="w-full border-2 border-dotted border-neutral-400 hover:border-neutral-600 cursor-pointer flex p-2">
            <i class="material-icons">add</i> {{ translate(add_dimension_translation, 'addDimension', language) }}
        </div>
        <div v-if="open" class="mpointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center p-12 z-50">
          <div class="absolute w-full h-full bg-neutral-900 opacity-50"></div>

          <div class="flex flex-col bg-white dark:bg-neutral-800 w-2/3 h-full mx-auto rounded shadow-lg z-50 overflow-hidden">
            <div class="w-full p-4 z-40 mr-10">
              <div class="mt-2">
                <div class="font-bold">{{ $t('name') }}</div>
                <v-input-languages :value="currentDim" :labelKey="'title'" :languages="languages.map(l => l.id)"></v-input-languages>
              </div>
            </div>
            <!-- Add margin if you want to see some of the overlay behind the modal-->
            <div class="text-left relative h-full overflow-y-auto px-2">
              <div v-for="(serie, index) in series" :key="index" >
                <div
                  v-if="openIndex === index || openIndex === -1"
                  @click="openIndex === index ? openIndex = -1 : openIndex = index"
                  class="bg-secondary hover:bg-opacity-75 text-white border-b border-neutral-400 p-2 cursor-pointer flex"
                  :class="{'border-t': index === 0, 'justify-between': openIndex === -1}"
                >
                  <svg v-if="openIndex === index" xmlns="http://www.w3.org/2000/svg" class="w-4 fill-current transform rotate-180 mr-2" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
                  {{serie.measure.name}}
                  <svg v-if="openIndex === -1" xmlns="http://www.w3.org/2000/svg" class="w-4 fill-current" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
                </div>
                <div v-if="openIndex === index">
                  <div v-for="(data, key, index) in serie.data" :key="index" class="p-2 mb-2">
                    <span class="font-bold"> {{translate(data[0], 'title', language)}} </span>

                    <div v-for="(attribute, index) in data" :key="index"
                      class="bg-neutral-200 dark:bg-neutral-700 hover:bg-secondary hover:text-white cursor-pointer p-2 mb-2"
                      :class="isInDimension(attribute) ? 'bg-green-600 text-white hover:bg-green-700 dark:bg-red-900' : ''"
                      @click="addAttributeToDimension(attribute)"
                    >
                       {{ translate(attribute, 'label', language) }}
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div class="w-full flex space-x-3 px-3 py-3 bg-white dark:bg-neutral-800">
              <button :disabled="currentDim.title === '' || currentDim.data.length === 0" class="primary w-full" @click="save()">OK</button>
            </div>
          </div>
        </div>
    </div>
</template>

<script>

import utilsMixin from '@/mixins/utils'
import VInputLanguages from '@/components/v-input-languages'
import { mapGetters } from 'vuex'
export default {
  mixins: [utilsMixin],
  components: {
    VInputLanguages
  },
  props: {
    series: {
      type: Object,
      required: true
    },
    initDimensions: {
      type: Array,
      required: true
    },
    language: {
      type: String,
      default: 'de'
    }
  },
  data: function () {
    return {
      openIndex: -1,
      dimensions: [],
      open: false,
      currentDim: { id: 0, name: '', data: [], dimension: true },
      currentIndex: -1,
      dimensions_translation: { dimensions: 'Dimensionen', dimensions_fr: 'Dimensions', dimensions_it: 'Dimensioni' },
      add_dimension_translation: { addDimension: 'Dimension hinzufügen', addDimension_fr: 'Ajouter une dimension', addDimension_it: 'Aggiungere dimensione' }
    }
  },
  computed: {
    ...mapGetters({
      languages: 'languages'
    }),
    nextId () {
      const ids = this.dimensions.map(object => {
        return object.id
      })
      return Math.max(...ids, 1) + 1
    }
  },
  methods: {
    onEscKeyPress (event) {
      if (event.key === 'Escape' && this.open) {
        this.open = false
      }
    },
    addDimension () {
      this.currentDim = { id: this.nextId, name: '', data: [], dimension: true }
      this.currentIndex = -1
      this.open = true
    },
    updateDimension (index, dimension) {
      this.currentDim = dimension
      this.currentIndex = index
      this.open = true
    },
    removeDimension (index) {
      this.dimensions.splice(index, 1)
    },
    addAttributeToDimension (attribute) {
      var index = this.currentDim.data.findIndex(d => d.measure.id === attribute.measure.id && d.key === attribute.key)
      if (index > -1) {
        this.currentDim.data.splice(index, 1)
      } else {
        this.currentDim.data.push({
          measure: {
            id: attribute.measure.id,
            name: attribute.measure.name
          },
          key: attribute.key
        })
      }
    },
    isInDimension (attribute) {
      return this.currentDim.data.findIndex(d => d.measure.id === attribute.measure.id && d.key === attribute.key) > -1
    },
    save () {
      if (this.currentIndex > -1) {
        this.dimensions[this.currentIndex] = this.currentDim
      } else {
        this.dimensions.push(this.currentDim)
      }
      this.$emit('update', this.dimensions)
      this.open = false
    }
  },
  mounted () {
    this.dimensions = this.initDimensions
    window.addEventListener('keydown', this.onEscKeyPress)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.onEscKeyPress)
  }
}
</script>

<style>

</style>
