<style type="text/css">
.apexcharts-bar-area {
  margin-top: 30px;
}
</style>
<template>
  <div
    class="h-full w-full border-2 bg-white p-2 dark:border-neutral-600 dark:bg-chart-dark"
    v-view.once="onceHandler"
  >
    <VueApexCharts
      v-if="(visible || print) && !hideGraphic"
      ref="chart"
      width="100%"
      height="100%"
      :options="chartOptions"
      :series="series"
    />
    <chart-menu v-if="visible && !readOnly" :chart="chart" />
    <div
      v-if="!visible"
      class="h-full w-full animate-pulse bg-neutral-200 dark:bg-neutral-800"
    />
    <div v-if="hideGraphic" class="absolute top-1/2 left-1/2 -translate-x-1/2 text-lg font-bold">
      {{ $t('no-data') }}
    </div>
    <LimitModal :seriesLength="seriesLength" :currentCountOfLabels="currentCountOfLabels" :maxLabels="MAX_CATEGORIES_LABELS"/>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { round } from 'lodash'
import utilsMixin from '@/mixins/utils'
import ChartMenu from '@/components/chartsFSP/ChartMenu.vue'
import LimitModal from '@/components/chartsFSP/LimitModal.vue'
import { mapGetters } from 'vuex'

function getDivergentColorsFunc (nCategories, colorVector) {
  const indexesColorVector = Array.from({ length: nCategories }, (_, i) => Math.round(i * (colorVector.length - 1) / (nCategories - 1)))
  const filteredColorVector = indexesColorVector.map(index => colorVector[index])
  return filteredColorVector
}

function mergeArraysOverwriting (array1, array2) {
  const mergedArray = [...array2]
  for (let i = 0; i < array1.length; i++) {
    mergedArray[i] = array1[i]
  }
  return mergedArray
}

export default {
  mixins: [utilsMixin],
  data () {
    return {
      height: 0,
      width: 0,
      MAX_CATEGORIES_LABELS: 100,
      currentCountOfLabels: 0,
      hideGraphic: false,
      visible: false,
      seriesData: [],
      seriesLength: null,
      isDarkMode: false
    }
  },
  props: {
    chart: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      default: 'de'
    },
    isEditedChart: {
      type: Boolean,
      default: false
    },
    print: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      resultBenchmarkForMeasureId: 'results/evaluationStore/resultBenchmarkForMeasureId',
      benchmarkNames: 'results/evaluationStore/benchmarkNames',
      filteredDataForChartId: 'results/evaluationStore/filteredDataForChartId',
      currentEvaluation: 'results/evaluations/detailItem'
    }),
    getFullRange () {
      const fullRange = []
      for (const series of this.seriesData) {
        if (series && Array.isArray(series.data)) {
          const lbase = series.data.map(item => {
            if (item && typeof item.y === 'number') {
              return Math.abs(item.y)
            } else if (typeof item === 'number') {
              return Math.abs(item)
            } else {
              return 0 // Default to 0 if neither item.y nor item is a valid number
            }
          })
          fullRange.push(...lbase)
        }
      }
      return fullRange.length > 0 ? Math.max(...fullRange) : 0
    },
    data () {
      return this.filteredDataForChartId(this.chart.i, this.chart.filters)
    },
    title () {
      if (!this.isEditedChart) {
        return this.translate(this.chart, 'title', this.language)
      } else {
        return this.translate(this.chart, 'title', this.chart.languageTabTitle ? this.chart.languageTabTitle : this.language)
      }
    },
    selectedSeries () {
      return this.chart.data
    },
    viewSetting () {
      return this.chart.viewSetting
    },
    splitSeries () {
      return this.chart.splits
    },
    horizontal () {
      return this.chart.viewSettings?.indexOf('horizontal') > -1
    },
    series () {
      return this.seriesData
    },
    subtitle () {
      if (!this.isEditedChart) {
        return this.translate(this.chart, 'subtitle', this.language)
      } else {
        return this.translate(this.chart, 'subtitle', this.chart.languageTabSubtitle ? this.chart.languageTabSubtitle : this.language)
      }
    },
    getWidth () {
      return this.width
    },
    benchmarkPalette () {
      return ['#d6c9f4', '#b59ae7', '#9277d6', '#775DD0', '#644bb1', '#523c93', '#403073']
    },
    colorPalette () {
      // old config first two colors: ['#559861', '#74B86B']
      const nCategories = this.seriesData.length
      const colorArrayANQ = ['#1d5a84', '#9ab5c2', '#6bc69b', '#1d5a84', '#477899', '#7097ad', '#9ab5c2', '#8abbb5', '#7bc0a8', '#6bc69b']
      const colorArrayFSP = ['#659086', '#B2F881', '#93D876', '#B2F881', '#B4F199', '#B6EAB1', '#B9E4C9', '#BBDDE1', '#BED7F9', '#C9C1D8']
      const colorVector = this.restrictToFspOnly ? colorArrayFSP : colorArrayANQ
      const resultColors = nCategories >= 3 ? mergeArraysOverwriting(getDivergentColorsFunc(nCategories, colorVector), colorVector) : colorVector
      return resultColors
    },
    categories () {
      return this.selectedSeries[0].data.map(item => item.label).reverse().map(item => item.toString())
    },
    getTitle () {
      return this.splitTitle(this.title, this.getWidth, { fontSize: '20px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
    },
    getSubTitle () {
      return this.splitTitle(this.subtitle, this.getWidth, { fontSize: '14px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
    },
    colorScheme () {
      return this.isDarkMode ? 'dark' : 'light'
    },
    restrictToFspOnly () {
      const current = this.currentEvaluation.measures
      return current.includes(65) || current.includes(66) || current.includes(101)
    },
    chartOptions () {
      return {
        dataLabels: { enabled: false },
        tooltip: {
          y: {
            formatter: (val, { dataPointIndex, seriesIndex }) => {
              return Math.abs(val)
            }
          }
        },
        legend: {
          show: true,
          customLegendItems: this.legends,
          markers: {
            fillColors: this.colors
          }
        },
        title: {
          text: this.getTitle,
          align: 'center',
          offsetY: this.readOnly ? 0 : 35,
          margin: 35,
          style: {
            fontSize: '20px',
            fontWeight: 'normal',
            fontFamily: 'Nunito, sans-serif',
            color: this.colorScheme === 'dark' ? '#3a92cf' : '#333333'
          }
        },
        subtitle: {
          text: this.getSubTitle,
          align: 'center',
          margin: this.getSubTitle ? 5 : 0,
          offsetX: 0,
          offsetY: this.getTitle.length * 25 + (this.readOnly ? 25 : 55),
          style: {
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: 'Nunito, sans-serif',
            color: this.colorScheme === 'dark' ? '#3a92cf' : '#777777'
          }
        },
        theme: {
          mode: this.colorScheme
        },
        colors: this.colorPalette,
        chart: {
          type: 'bar',
          stacked: !(this.seriesData.length >= 3),
          grid: {
            xaxis: {
              lines: {
                show: false
              }
            }
          },
          fontFamily: 'Nunito, sans-serif',
          toolbar: {
            show: false,
            offsetX: -30
          },
          events: {
            mounted: (chart) => {
              if (this.$refs.chart === undefined) return
              this.height = this.$refs.chart.$el.clientHeight
              this.width = this.$refs.chart.$el.clientWidth
              chart.windowResizeHandler()
            },
            updated: (chartContext, config) => {
              if (this.$refs.chart === undefined) return
              this.height = this.$refs.chart.$el.clientHeight
              this.width = this.$refs.chart.$el.clientWidth
              if (this.showErrorBars) {
                this.buildErrorBars()
              }
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '90%'
          }
        },
        yaxis: {
          min: -this.getFullRange - 20,
          max: this.getFullRange + 20
        },
        xaxis: {
          categories: this.categories,
          labels: {
            formatter: function (val) {
              return Math.abs(Math.round(val))
            }
          }
        }
      }
    }
  },
  methods: {
    checkLimitOfCategoriesInGraphic (currentCountOfLabels) {
      if (currentCountOfLabels > this.MAX_CATEGORIES_LABELS) {
        this.hideGraphic = true
      }
    },
    setupData () {
      if (!this.visible || this.data.length <= 0) {
        this.seriesData = []
        return
      }
      let result = []
      const benchmarksLegendObject = {} // depends on viewSetting
      const benchmarksLegend = []
      const benchmarksColor = []
      const benchmarksColorObject = {} // depends on viewSetting
      const originalLegend = []
      const originalColor = []
      const benchmarkValueObject = {}

      const temporary = this.buildSeries(this.data)
      result = temporary[0]
      // Fill in 0 frequencies:
      const minAge = Math.min(...Object.keys(result))
      const maxAge = Math.max(...Object.keys(result))
      for (let age = minAge; age <= maxAge; age++) {
        if (!(age in result)) {
          result[age] = 0
        }
      }

      // show pop-up window if results exceed threshold (keep fluent UX)
      this.hideGraphic = false
      this.checkLimitOfCategoriesInGraphic(result.length)
      this.currentCountOfLabels = result.length

      this.seriesData = result
      let fullArray = []
      for (const i in this.seriesData) {
        const baseArray = temporary[Number(i) + 1]
        fullArray = fullArray.concat(baseArray)
      }
      this.fullArray = [...new Set(fullArray)].sort((a, b) => a - b)

      for (const i in this.seriesData) {
        const colors = this.colorPalette[i]
        const name = this.seriesData[i].name

        if (this.viewSetting && this.viewSetting.length > 0) {
          originalLegend.push(name)
          originalColor.push(colors)
          const temporary2 = this.seriesData[i].data
          this.seriesData[i].data = temporary2.map(
            (item, index) => {
              const goals = []
              for (const v of this.viewSetting) {
                const ind = this.viewSetting.indexOf(v)
                const color = this.benchmarkPalette[ind]
                const benchmark = v[i].data
                const benchmarkName = v[i]._title
                benchmarksColor.push(color)
                benchmarksLegend.push(benchmarkName)
                goals.push({
                  strokeHeight: 6,
                  strokeColor: color,
                  value: benchmark[index],
                  name: benchmarkName
                })

                // Initialize arrays for benchmarkName if it doesn't exist
                if (!benchmarksLegendObject[benchmarkName]) {
                  benchmarksLegendObject[benchmarkName] = []
                }

                if (!benchmarksColorObject[benchmarkName]) {
                  benchmarksColorObject[benchmarkName] = []
                }

                if (!benchmarkValueObject[benchmarkName]) {
                  benchmarkValueObject[benchmarkName] = []
                }

                // Push the benchmark[index] value to the corresponding array
                benchmarksLegendObject[benchmarkName] = (benchmarkName)

                benchmarksColorObject[benchmarkName] = (color)

                const absoluteBenchmark = Math.abs(benchmark[index])
                benchmarkValueObject[benchmarkName].push(absoluteBenchmark)
              }
              return {
                x: '',
                y: item,
                goals: goals
              }
            })
        }
      }
      if (this.viewSetting && this.viewSetting.length > 0) {
        this.fullArray = this.fullArray.reverse()
        this.fullArray = this.fullArray.concat(this.fullArray)
      }
      this.colors = originalColor.concat([...new Set(benchmarksColor)]) // empty in case of empty viewSetting
      this.legends = originalLegend.concat([...new Set(benchmarksLegend)]) // empty in case of empty viewSetting
    },
    buildSeries (data) {
      if (!this.selectedSeries[0] || !this.splitSeries[0]) {
        console.log('failure')
        return
      }

      const processedMap = []
      const processedData = []
      const { key: valueKey, blow_in_graph: filtering } = this.selectedSeries[0]
      const { key: valueSplit, data: splitData } = this.splitSeries[0]

      for (const splitItem of splitData) {
        const base = this.restrictToFspOnly
          ? data.filter(item => {
            const condition = (item.attributes.record_id === 1 || !filtering) && item.attributes[valueSplit] === splitItem.value
            return condition
          })
          : data.filter(item => {
            const condition = String(item.attributes[valueSplit]) === String(splitItem.value)
            return condition
          })

        const map = base.map(item => item.attributes[valueKey]).filter(Boolean)

        processedMap.push(map)

        const baseArray = this.categories.map(category => {
          const count = map.reduce((acc, currentValue) => {
            return currentValue === Number(category) ? acc + 1 : acc
          }, 0)
          return count
        })

        const counter = baseArray.map(count => {
          const percentage = map.length > 0 ? round(count / map.length * 100, 1) : 0
          return percentage
        })

        // Deep copy using JSON methods
        const dataObject = {
          name: splitItem.label,
          data: JSON.parse(JSON.stringify(baseArray)),
          label: JSON.parse(JSON.stringify(baseArray)),
          total: counter
        }
        processedData.push(dataObject)
      }

      // Turn one half of the data negative.
      const threshold = Math.ceil(processedData.length / 2)
      processedData.forEach((item, index) => {
        if (index < threshold) {
          item.data = item.data.map(d => (typeof d === 'number' && d > 0 ? -d : d))
        }
      })

      return [processedData, ...processedMap]
    },
    buildErrorBars () {
      const groups = document.querySelectorAll('g[className="apexcharts-bar-goals-groups"]')
      for (var i = 0; i < groups.length; ++i) {
        var group = groups[i]

        const errorBars = group.querySelectorAll('line[stroke="#aaaaaa"]')

        if (errorBars.length === 2) {
          var errorBar1 = errorBars[0]
          var errorBar2 = errorBars[1]
          var line = document.createElement('line')
          const yVal = errorBar1.y1.baseVal.value + ((errorBar1.y2.baseVal.value - errorBar1.y1.baseVal.value) / 2)
          line.id = `${errorBar1.id}_${errorBar2.id}`
          line.setAttribute('stroke', '#aaaaaa')
          line.setAttribute('stroke-width', '2')
          line.setAttribute('x1', errorBar1.x1.baseVal.value)
          line.setAttribute('x2', errorBar2.x1.baseVal.value)
          line.setAttribute('y1', yVal)
          line.setAttribute('y2', yVal)
          line.setAttribute('stroke-dasharray', 0)
          line.setAttribute('stroke-linecap', 'butt')
          line.setAttribute('transform', 'matrix(1,0,0,1,0,0)')
          // shorten the bars
          errorBar1.setAttribute('y1', errorBar1.y1.baseVal.value + 4)
          errorBar1.setAttribute('y2', errorBar1.y2.baseVal.value - 4)
          errorBar2.setAttribute('y1', errorBar2.y1.baseVal.value + 4)
          errorBar2.setAttribute('y2', errorBar2.y2.baseVal.value - 4)

          group.append(line)
          var newVal = group.innerHTML
          group.innerHTML = newVal
        }
      }
    },
    onceHandler (e) {
      this.visible = true
    }
  },
  mounted () {
    this.setupData()
    this.isDarkMode = document.documentElement.classList.contains('dark')
  },
  components: {
    VueApexCharts,
    LimitModal,
    ChartMenu
  },
  watch: {
    viewSetting: {
      handler (val, old) {
        // if (this.$parent.isPreview) {
        this.setupData()
        // }
      }
    },
    selectedSeries: {
      handler (val) {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    splitSeries: {
      handler (val) {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    groups: {
      handler (val) {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    data: {
      handler (val) {
        this.setupData()
      }
    },
    visible: {
      handler (val) {
        this.setupData()
      }
    }
  }
}
</script>
