<style type="text/css">
.apexcharts-bar-area {
  margin-top: 30px;
}
</style>
<template>
  <div
    class="h-full w-full border-2 bg-white p-2 dark:border-neutral-600 dark:bg-chart-dark"
    v-view.once="onceHandler"
  >
    <VueApexCharts
      v-if="visible || print"
      ref="chart"
      width="100%"
      height="100%"
      :options="chartOptions"
      :series="series"
    />
    <chart-menu v-if="visible && !readOnly" :chart="chart" />
    <div
      v-if="!visible"
      class="h-full w-full animate-pulse bg-neutral-200 dark:bg-neutral-800"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import utilsMixin from '@/mixins/utils'
import ChartMenu from '@/components/charts/ChartMenu.vue'
import { mapGetters } from 'vuex'
// import { round, uniqBy } from 'lodash'

export default {
  mixins: [utilsMixin],
  data () {
    return {
      height: 0,
      width: 0,
      visible: false,
      seriesData: [],
      isDarkMode: false,
      annotationsVar: {}
    }
  },
  props: {
    chart: {
      type: Object,
      required: true
    },
    // annotations: {
    //   type: Object,
    //   required: false,
    //   default: () => {}
    // },
    language: {
      type: String,
      default: 'de'
    },
    print: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      resultBenchmarkForMeasureId: 'results/evaluationStore/resultBenchmarkForMeasureId',
      benchmarkNames: 'results/evaluationStore/benchmarkNames',
      filteredDataForChartId: 'results/evaluationStore/filteredDataForChartId'
    }),
    data () {
      return this.filteredDataForChartId(this.chart.i, this.chart.filters)
    },
    title () {
      return this.translate(this.chart, 'title', this.chart.languageTabTitle ? this.chart.languageTabTitle : this.language)
    },
    annotations () {
      return this.annotationsVar
    },
    isSplit () {
      return this.chart.splits.length > 0
    },
    selectedSeries () {
      return this.chart.data
    },
    splitSeries () {
      return this.chart.splits.length > 0 ? this.chart.splits[0].data : []
    },
    horizontal () {
      return this.chart.viewSettings?.indexOf('horizontal') > -1
    },
    series () {
      return this.seriesData
    },
    subtitle () {
      return this.translate(this.chart, 'subtitle', this.chart.languageTabSubtitle ? this.chart.languageTabSubtitle : this.language)
    },
    getWidth () {
      return this.width
    },
    getTitle () {
      return this.splitTitle(this.title, this.getWidth, { fontSize: '20px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
    },
    getSubTitle () {
      return this.splitTitle(this.subtitle, this.getWidth, { fontSize: '14px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
    },
    categories () {
      return Object.keys(this.seriesData[0].data)
    },
    colorScheme () {
      return this.isDarkMode ? 'dark' : 'light'
    },
    chartOptions () {
      return {
        dataLabels: { enabled: false },
        tooltip: {
          y: {
            formatter: (val, { dataPointIndex, seriesIndex }) => {
              return Math.abs(val)
            }
          }
        },
        title: {
          text: this.getTitle,
          align: 'center',
          offsetY: this.readOnly ? 0 : 35,
          margin: 35,
          style: {
            fontSize: '20px',
            fontWeight: 'normal',
            fontFamily: 'Nunito, sans-serif',
            color: this.colorScheme === 'dark' ? '#3a92cf' : '#333333'
          }
        },
        subtitle: {
          text: this.getSubTitle,
          align: 'center',
          margin: this.getSubTitle ? 5 : 0,
          offsetX: 0,
          offsetY: this.getTitle.length * 25 + (this.readOnly ? 25 : 55),
          style: {
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: 'Nunito, sans-serif',
            color: this.colorScheme === 'dark' ? '#3a92cf' : '#777777'
          }
        },
        theme: {
          mode: this.colorScheme
        },
        annotations: this.annotations,
        // yaxis: [
        //   {
        //     y: '10',
        //     borderColor: '#00E396',
        //     label: {
        //       borderColor: '#00E396',
        //       style: {
        //         color: '#fff',
        //         background: '#00E396'
        //       },
        //       text: 'Y-axis annotation on 8800'
        //     }
        //   }
        // ]
        chart: {
          type: 'bar',
          grid: {
            xaxis: {
              lines: {
                show: false
              }
            }
          },
          // stacked: true,
          fontFamily: 'Nunito, sans-serif',
          toolbar: {
            show: false,
            offsetX: -30
          },
          events: {
            mounted: (chart) => {
              if (this.$refs.chart === undefined) return
              this.height = this.$refs.chart.$el.clientHeight
              this.width = this.$refs.chart.$el.clientWidth
              chart.windowResizeHandler()
            },
            updated: (chartContext, config) => {
              if (this.$refs.chart === undefined) return
              this.height = this.$refs.chart.$el.clientHeight
              this.width = this.$refs.chart.$el.clientWidth
              if (this.showErrorBars) {
                this.buildErrorBars()
              }
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '80%'
          }
        },
        xaxis: {
          categories: this.categories,
          title: {
            text: 'Percent'
          },
          labels: {
            formatter: function (val) {
              return Math.abs(Math.round(val)) + '%'
            }
          }
        },
        yaxis: {
          min: -100,
          max: 100,
          title: {
            text: 'Age'
          }
        }
      }
    }
  },
  methods: {
    setupData () {
      if (!this.visible || this.data.length <= 0) {
        this.seriesData = []
        return
      }
      var result = []

      result = this.buildSeries(result, this.data)
      // Fill in 0 frequencies:

      const minAge = Math.min(...Object.keys(result))
      const maxAge = Math.min(...Object.keys(result))

      for (let age = minAge; age <= maxAge; age++) {
        if (!(age in result)) {
          result[age] = 0
        }
      }

      this.seriesData = result
      this.categoriesVar = Object.keys(result[0].data)

      const meanLeft = String(this.calculateMeanFrequency(this.seriesData[0].data))
      const meanRight = String(this.calculateMeanFrequency(this.seriesData[1].data)) // + 25) TODO : WHY ?

      this.annotationsVar = {
        yaxis: [
          {
            y: meanLeft,
            borderColor: '#FF529D',
            borderWidth: 2,
            strokeDashArray: 0,
            label: {
              borderColor: '#FF529D',
              style: {
                color: '#fff',
                background: '#FF529D'
              },
              text: `${this.$t('mean')} - ${this.seriesData[0].name}`
            }
          },
          {
            y: meanRight,
            borderColor: '#287BDB',
            borderWidth: 2,
            strokeDashArray: 0,
            label: {
              borderColor: '#287BDB',
              style: {
                color: '#fff',
                background: '#287BDB'
              },
              text: `${this.$t('mean')} - ${this.seriesData[1].name}`
            }
          }
        ]
      }
    },
    calculateMeanFrequency (obj) {
      let totalFrequency = 0
      let ageCount = 0

      for (const age in obj) {
        const frequency = Math.abs([age])
        totalFrequency += frequency
        ageCount++
      }

      // Calculate the mean frequency
      const mean = ageCount > 0 ? totalFrequency / ageCount : 0
      return Math.abs(Math.floor(mean))
    },
    buildSeries (result, data, groupIx = 0) {
      if (!this.chart.data[0] || !this.chart.groups[0]) {
        console.log('failure')
        return
      }
      const valueKey = this.chart.data[0].key
      const valueGroup = this.chart.groups[0].key

      if (!valueKey || !valueGroup) return []

      const resultMap = new Map()

      // let index = 0
      data.forEach(dataPoint => {
        // if (index > 100) return

        const groupAttributeValue = dataPoint.attributes[valueGroup]

        if (!resultMap.has(groupAttributeValue) && resultMap.size < 2) {
          resultMap.set(groupAttributeValue, [])
        }
        resultMap.get(groupAttributeValue).push(dataPoint.attributes[valueKey])
        // index++
      })

      const processedData = []

      const summarize = (data) => {
        const frequencyMap = {}

        data.forEach(point => {
          frequencyMap[point] = (frequencyMap[point] || 0) + 1
        })

        return Object.values(frequencyMap)
      }

      for (const [name, data] of resultMap.entries()) {
        processedData.push({ name, data: summarize(data) })
      }

      // Turn one half of the data negative.
      processedData[1].data = processedData[1].data.map(d => d > 0 ? -d : d)

      return processedData
    },
    buildErrorBars () {
      const groups = document.querySelectorAll('g[className="apexcharts-bar-goals-groups"]')
      for (var i = 0; i < groups.length; ++i) {
        var group = groups[i]

        const errorBars = group.querySelectorAll('line[stroke="#aaaaaa"]')

        if (errorBars.length === 2) {
          var errorBar1 = errorBars[0]
          var errorBar2 = errorBars[1]
          var line = document.createElement('line')
          const yVal = errorBar1.y1.baseVal.value + ((errorBar1.y2.baseVal.value - errorBar1.y1.baseVal.value) / 2)
          line.id = `${errorBar1.id}_${errorBar2.id}`
          line.setAttribute('stroke', '#aaaaaa')
          line.setAttribute('stroke-width', '2')
          line.setAttribute('x1', errorBar1.x1.baseVal.value)
          line.setAttribute('x2', errorBar2.x1.baseVal.value)
          line.setAttribute('y1', yVal)
          line.setAttribute('y2', yVal)
          line.setAttribute('stroke-dasharray', 0)
          line.setAttribute('stroke-linecap', 'butt')
          line.setAttribute('transform', 'matrix(1,0,0,1,0,0)')
          // shorten the bars
          errorBar1.setAttribute('y1', errorBar1.y1.baseVal.value + 4)
          errorBar1.setAttribute('y2', errorBar1.y2.baseVal.value - 4)
          errorBar2.setAttribute('y1', errorBar2.y1.baseVal.value + 4)
          errorBar2.setAttribute('y2', errorBar2.y2.baseVal.value - 4)

          group.append(line)
          var newVal = group.innerHTML
          group.innerHTML = newVal
        }
      }
    },
    onceHandler (e) {
      this.visible = true
    }
  },
  mounted () {
    this.setupData()
    this.isDarkMode = document.documentElement.classList.contains('dark')
  },
  components: {
    VueApexCharts,
    ChartMenu
  },
  watch: {
    selectedSeries: {
      handler (val) {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    splitSeries: {
      handler (val) {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    groups: {
      handler (val) {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    data: {
      handler (val) {
        this.setupData()
      }
    },
    visible: {
      handler (val) {
        this.setupData()
      }
    }
  }
}
</script>
