<template>
  <div class="flex">
    <a class="mr-2 text-white dark:text-neutral-800 cursor-pointer" v-for="(lang, i ) in langs" v-bind:class="{ 'font-bold': $i18n.locale == lang }" :key="`Lang${i}`" @click="changeLang(lang)">{{ lang }}</a>
  </div>
</template>

<script>
export default {
  data () {
    return {
      langs: ['de', 'fr', 'it']
    }
  },
  methods: {
    changeLang (lang) {
      this.$root.$i18n.locale = lang
    }
  }
}
</script>
